const iconCb = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            d="M22 10v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V10h20zm0-2H2V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4zm-7 8v2h4v-2h-4z"
            fill="rgba(40,120,2,1)"
        />
    </svg>
)

const iconStore = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            d="M21 13v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-7H2v-2l1-5h18l1 5v2h-1zM5 13v6h14v-6H5zm1 1h8v3H6v-3zM3 3h18v2H3V3z"
            fill="rgba(40,120,2,1)"
        />
    </svg>
)

export const configPaymentMethod = [
    {
        value: 'CB',
        id: 'pay-cb',
        icon: iconCb,
        title: 'Règlement en ligne',
        body: 'Gagnez du temps en payant par CB et évitez la queue lors de la distribution !',
        notice: 'Choisissez cette option pour payer en ligne et gagnez du temps au moment du retrait.',
    },
    {
        value: 'Offline',
        id: 'pay-offline',
        icon: iconStore,
        title: 'Règlement sur place',
        body: 'Votre commande est réservée, vous payez sur place par chèque, espèces ou CB lors du retrait.',
        notice: 'Choisissez cette option si vous souhaitez payer sur place au moment du retrait.',
    },
]
