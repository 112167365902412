import { run as pickProperties } from '@kelbongoo/shared-methods/utils/pickProperties'
import { wrapClientApiCall } from '../../../utils'
import { failureCallback } from './failureCallback'

const createProps = [
    'firstname',
    'lastname',
    'email',
    'default_locale',
    'signup_reason',
    'telephone',
    'address_street',
    'address_city',
    'address_zip',
    'address_code',
    'address_instructions',
]

export const createConsumer = params => {
    const body = pickProperties({ ...params, }, createProps)

    return wrapClientApiCall({
        actionRoot: 'CREATE_CONSUMER',
        url: `create_consumer`,
        method: 'POST',
        body,
        hasJsonResponse: true,
        failureCallback,
    })
}
