import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import ChevronUp from '../../../../assets/chevron-up.svg'
import ChevronDown from '../../../../assets/chevron-down.svg'
import TheMap from './TheMap'
import CardPartenaire from './CardPartenaire'
import Styles from './styles.module.css'
import { getCenter } from './TheMap/helpers'
import MapIcon from '../../../../assets/mapIcon.svg'
import PlacesAutocomplete from './TheMap/PlaceAutocomplete'
import CloseIcon from '../../../../assets/closeCross.svg'

export const RetraitBoutiqueDetail = ({
    locales,
    eventEmitter,
    handleLocaleUnclick,
    handleLocaleClick,
    handleSelectSchedule,
    currentUser,
    shopIsSelect,
}) => {
    // Creating states
    const [selectedLocale, setSelectedLocale] = useState(null)
    const [sortedLocales, setSortedLocales] = useState(null)
    const [mapCenter, setMapCenter] = useState(getCenter(locales))
    const [selectedLatLng, setSelectedLatLng] = useState(null)
    const [selectedBoutique, setSelectedBoutique] = useState(null)
    const [showMap, setShowMap] = useState(true)
    const [popupVisible, setPopupVisible] = useState(false)
    const [openPanel, setOpenPanel] = useState(
        new Array(locales.length).fill(false)
    )

    // Creating refs
    const mapRef = useRef()
    const cardListRef = useRef(null)

    // Helper function to calculate distances
    function calculateDistance(latlng1, latlng2) {
        const { lat: lat1, lng: lng1 } = latlng1
        const { lat: lat2, lng: lng2 } = latlng2
        const toRad = angle => (Math.PI / 180) * angle
        const R = 6371

        const dLat = toRad(lat2 - lat1)
        const dLng = toRad(lng2 - lng1)

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) *
            Math.cos(toRad(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c

        return distance
    }

    // Make pop-up appear only once
    useEffect(() => {
        let pop_status = localStorage.getItem('pop_status');
        if (pop_status !== 'seen') {
            setPopupVisible(true);
            localStorage.setItem('pop_status', 'seen');
        }
        else {
            setPopupVisible(false)
        }
    }, [])

    // Create sortedLocales array
    useEffect(() => {
        // Tag new locales 
        let today = new Date()
        locales.forEach(l => {
            if (l.createdAt) {
                let createdAt = new Date(l.createdAt)
                if (((today - createdAt) / 1000 / 60 / 60 / 24) < 60) {
                    l['new'] = true
                }
                else {
                    l['new'] = false
                }
            }
            else {
                l['new'] = false
            }
        })

        // Create sortedLocales temp array
        let tempSortedLocales
        if (selectedLatLng !== null) {
            tempSortedLocales = locales.slice(0).sort((locale1, locale2) => {
                const distance1 = calculateDistance(
                    locale1.geolocation,
                    selectedLatLng
                )
                const distance2 = calculateDistance(
                    locale2.geolocation,
                    selectedLatLng
                )
                return distance1 - distance2
            })
        } else {
            tempSortedLocales = locales.slice(0).sort((locale1, locale2) => {
                const salesCount1 = locale1.schedules.length
                const salesCount2 = locale2.schedules.length
                return Number(locale2.new) - Number(locale1.new) || salesCount2 - salesCount1
            })
        }

        // Filter out certain locales
        if (currentUser._id) {
            const companyDomain = `@${currentUser.email.split('@')[1]}`;
            const companyLocaleIndex = tempSortedLocales.findIndex(locale => locale.company_domain === companyDomain);
            if (companyLocaleIndex !== -1) {
                const companyLocale = tempSortedLocales.splice(companyLocaleIndex, 1)[0];
                tempSortedLocales.unshift(companyLocale);
            }
        }

        setSortedLocales(tempSortedLocales)
    }, [selectedLatLng])

    return (
        <>
            {!showMap && (
                <div
                    className={Styles.displayMapContainer}
                    onClick={() => {
                        setShowMap(true)
                        window.scrollTo(0, 0)
                    }}
                >
                    <p className={Styles.displayMapContainerText}>Carte</p>
                    <Image src={MapIcon} alt='Ouvrir la carte' />
                </div>
            )}

            {popupVisible && window.innerWidth < 1000 && (
                <div className={Styles.overlayBackground}></div>
            )}

            {popupVisible && window.innerWidth < 1000 && (
                <div className={Styles.popupContainer}>
                    <Image
                        src={CloseIcon}
                        className={Styles.closeIcon}
                        onClick={() => setPopupVisible(false)}
                        alt="Fermer la popup"
                    />

                    <div className={Styles.popupContent}>
                        <div className={Styles.popupText}>
                            1. Je choisis mon point de retrait
                        </div>

                        <div className={Styles.popupText}>
                            2. Je fais mon marché
                        </div>
                        <div className={Styles.lastPopupText}>
                            3. Je déguste mes produits
                        </div>
                    </div>
                </div>
            )}

            <div className={Styles.mobileParallax}>
                <div
                    className={
                        window.innerWidth > 1000
                            ? Styles.Magasins
                            : !showMap
                                ? Styles.Magasins
                                : Styles.MobileMagasins
                    }
                    data-testid="boutiques-list-container"
                >
                    <p
                        className={
                            window.innerWidth > 1000
                                ? Styles.headTitle
                                : showMap
                                    ? Styles.headTitle
                                    : Styles.headTitleMobile
                        }
                    >
                        Pour passer commande,
                        choisissez votre lieu et votre jour de retrait :
                    </p>

                    <div
                        className={Styles.cardList}
                        ref={cardListRef}
                    >
                        {window.innerWidth < 1000 && !showMap && (
                            <PlacesAutocomplete
                                mapRef={mapRef}
                                isPositionAbsolute={false}
                                locales={locales}
                                setSelectedLatLng={setSelectedLatLng}
                            />
                        )}
                        <div
                            className={Styles.expandContainer}
                            onClick={() => setShowMap(!showMap)}
                        >
                            <p className={Styles.pointRetrait}>
                                {selectedLatLng === null ? 'Tous les lieux de retraits' : 'Près de moi'}
                            </p>
                            {showMap ? (
                                <Image src={ChevronUp} alt="Fermer la carte" />
                            ) : (
                                <Image src={ChevronDown} alt="Ouvrir la carte" />
                            )}
                        </div>

                        {sortedLocales && sortedLocales.map((locale, index) => {
                            return (
                                <CardPartenaire
                                    locale={locale}
                                    currentUser={currentUser}
                                    selectedBoutique={selectedBoutique}
                                    handleLocaleClick={handleLocaleClick}
                                    handleLocaleUnclick={handleLocaleUnclick}
                                    eventEmitter={eventEmitter}
                                    handleNavigate={handleSelectSchedule}
                                    mapRef={mapRef}
                                    setSelectedBoutique={setSelectedBoutique}
                                    localeLength={locales.length}
                                    openPanel={openPanel}
                                    index={index}
                                    setOpenPanel={setOpenPanel}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                />
                            )
                        })}

                        {!currentUser.loggedin && !shopIsSelect && (
                            <Link to="/creer-un-compte">
                                <Button
                                    style={{
                                        marginTop: '20px',
                                        marginBottom: '40px',
                                    }}
                                    secondary
                                    size="large"
                                    fluid
                                >
                                    Je veux m'inscrire !
                                    <br />
                                    <span style={{ fontSize: '0.7em' }}>
                                        gratuit, sans engagement
                                    </span>
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>

                {showMap && (
                    <div className={Styles.TheMap}>
                        {window.innerWidth <= 1000 && (
                            <p className={Styles.headTitleMobile}>
                                Pour passer commande,
                                choisissez votre lieu et votre jour de retrait :
                            </p>
                        )}
                        <TheMap
                            mapRef={mapRef}
                            setSelectedBoutique={setSelectedBoutique}
                            cardListRef={cardListRef}
                            center={mapCenter}
                            setSelectedLatLng={setSelectedLatLng}
                            selectedLatLng={selectedLatLng}
                            openPanel={openPanel}
                            setOpenPanel={setOpenPanel}
                            setCenter={setMapCenter}
                            selectedLocale={selectedLocale}
                            locales={sortedLocales}
                            eventEmitter={eventEmitter}
                            handleLocaleClick={handleLocaleClick}
                            handleSelect={handleSelectSchedule}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
