import { validateConsumerProfileUpdate } from './validateConsumerProfileUpdate'

export const handleChange = function (e, data) {
    let { name, value, checked } = e.target
    if (!name) {
        ;({ name, value, checked } = data)
    }

    if (['send_launch_email', 'send_closing_email'].includes(name)) {
        value = checked
    }

    this.setState({ [name]: value }, () => {
        if (validateConsumerProfileUpdate(this.props.currentUser, this.state)) {
            this.setState({ validated: true })
        } else {
            this.setState({ validated: false })
        }
    })
}
