import { MIN_WIDTH } from '../../../../../app-constants'
import React from 'react'
import { Icon, Transition } from 'semantic-ui-react'
import Responsive from "../../../../ui/Responsive"
import Styles from './styles.module.css'

const CartHeader = ({
    currentCartQuantity = 0,
    currentCartMessage,
    ...rest
}) => {
    let icon =
        typeof currentCartMessage === 'undefined'
            ? 'shopping bag'
            : 'exclamation circle '
    let customIconClass =
        typeof currentCartMessage === 'undefined'
            ? `${Styles.TriggerIcon} ${Styles.shoppingBagTriggerIcon}`
            : `${Styles.TriggerIcon}, ${Styles.exclamationCircleTriggerIcon}`

    return (
        <div
            data-testid="cart-trigger-inner"
            {...rest}
        >
            <Icon.Group>
                <Icon
                    name={icon}
                    size="big"
                    className={customIconClass}
                />
                <Transition>
                    <span
                        className={Styles.currentCartQuantity}
                        title="cart-trigger-quantity"
                    >
                        {currentCartQuantity}
                    </span>
                </Transition>
            </Icon.Group>

            <Responsive
                as="div"
                minWidth={MIN_WIDTH.MD}
                className={Styles.panierText}
            >
                Panier
            </Responsive>
        </div>
    )
}

export default CartHeader
