import React from 'react'
import {
    Header,
    Divider,
    Card,
    Visibility,
} from 'semantic-ui-react'
import Responsive from "../../../../../ui/Responsive"

import ProductCard from '../../../ProductCard'
import CartCard from '../../../CartCard'
import Styles from './styles.module.css'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../app-constants'

export const FamilyView = ({
    category,
    family,
    addCart,
    onPassNewSection,
    onChangeValue,
    onOpenZoom,
    isFull,
    index,
}) => (
    <Visibility
        onTopPassed={(e, params) =>
            onPassNewSection(
                {
                    category: category._id,
                    family: family._id,
                },
                e,
                params
            )
        }
        onTopPassedReverse={() =>
            index === 0 &&
            onPassNewSection({ category: null, family: null }, null, {})
        }
        onBottomPassedReverse={(e, params) =>
            onPassNewSection(
                { category: category._id, family: family._id },
                e,
                params
            )
        }
        offset={200}
        once={false}
        updateOn="repaint"
        key={family._id}
    >
        <div id={`key-${family._id}`}>
            <Divider
                horizontal
                className={Styles.familyDivider}
            >
                <Responsive maxWidth={MAX_WIDTH.XS}>
                    <Header
                        as="h3"
                        data-testid="family-header"
                    >
                        <Header.Content>{family.name}</Header.Content>
                    </Header>
                </Responsive>

                <Responsive minWidth={MIN_WIDTH.SM}>
                    <Header
                        as="h3"
                        data-testid="family-header"
                    >
                        <Header.Content>{family.name}</Header.Content>
                    </Header>
                </Responsive>
            </Divider>

            {family.subtitle && (
                <div
                    dangerouslySetInnerHTML={{ __html: family.subtitle }}
                    className={Styles.familySubtitle}
                    data-testid="family-subtitle"
                />
            )}

            <Card.Group centered>
                {family.products.map(entity =>
                    typeof entity.items !== 'undefined' ? (
                        <CartCard
                            cart={entity}
                            key={entity._id}
                            onChangeValue={onChangeValue}
                            addCart={addCart}
                        />
                    ) : (
                        <ProductCard
                            product={entity}
                            producer={entity.producer}
                            onChangeValue={onChangeValue}
                            onOpenZoom={onOpenZoom}
                            key={entity.producerproduct_id}
                            isFull={isFull}
                        />
                    )
                )}
            </Card.Group>

            <br />
        </div>
    </Visibility>
)
