import 'whatwg-fetch'
import * as Types from '../types'
import { getContentfulClient } from '../../utils/getContentfulClient'

const contentfulClient = getContentfulClient()

/**
 * fetch all possible "source" options for the user signup form from contentful, and then format into useable data
 * @returns {function} - dispatchable function to fetch signup options
 */

export const fetchContentfulLocales = () => {
    return dispatch => {
        dispatch({
            type: Types.FETCH_CONTENTFUL_LOCALES_REQUEST,
            startDate: new Date(),
        })

        return contentfulClient
            .getEntries({
                content_type: 'boutique',
                select: 'fields.image,fields.slug,fields.horaires,fields.subtitle', // must not put a space between elements !!!
            })
            .then(function (result) {
                let items =
                    result &&
                    result.items &&
                    result.items.map(p => {
                        const output = {}
                        if (!p.fields) {
                            return output
                        }

                        if (
                            p.fields.image &&
                            p.fields.image.fields &&
                            p.fields.image.fields.file
                        ) {
                            output.image = p.fields.image.fields.file.url
                        }
                        if (p.fields.slug) {
                            output.slug = p.fields.slug
                        }
                        if (p.fields.horaires) {
                            output.horaires = p.fields.horaires
                        }
                        if (p.fields.subtitle) {
                            output.subtitle = p.fields.subtitle
                        }
                        return output
                    })

                dispatch({
                    type: Types.FETCH_CONTENTFUL_LOCALES_SUCCESS,
                    items,
                })
            })
            .catch(error =>
                dispatch({
                    type: Types.FETCH_CONTENTFUL_LOCALES_FAIL,
                    error,
                })
            )
    }
}
