import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CartList from './CartList'
import { Header, Icon, Dimmer, Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { Image } from 'semantic-ui-react'

import ErrorBoundary from '../../layout/ErrorBoundary'
import { updateCartItem, fetchInit, scheduleSyncOrder } from '../../../actions'
import { mapStateToProps } from './mapStateToProps'
import { PersistentCheckoutBar } from './PersistentCheckoutBar'
import horloge from '../../../assets/time-and-date.png'


import Styles from './styles.module.css'

class CartDetailsBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timer: null,
        }

        this.handleCartChange = this.handleCartChange.bind(this)
        this.setTimer = this.setTimer.bind(this)
    }

    UNSAFE_componentWillMount() {
        if (!this.props.ready) {
            this.props.fetchInit()
        }

        // Check if cart expired
        this.props.scheduleSyncOrder()
        window.scrollTo(0, 0)
    }

    componentDidMount() {
        if (this.props.order && this.props.order.cartExpiration) {
            this.setTimer(this.props.order.cartExpiration)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.order && this.props.order.cartExpiration !== prevProps.order.cartExpiration) {
            this.setTimer(this.props.order.cartExpiration)
        }
    }

    componentWillUnmount() {
        this.setState({
            timer: null,
        })
    }

    handleCartChange({ productId, quantity }) {
        this.props.updateCartItem({ productId, quantity })
        this.props.scheduleSyncOrder()
    }

    setTimer(cartExpiration) {
        let expirationDate = new Date(cartExpiration)
        let now = new Date()

        // Update timer
        if (expirationDate > now) {
            // Initialize timer
            this.setState({
                timer: Math.round((expirationDate - now) / 1000 / 60),
            })

            // Reset timer every 1 min
            setInterval(() => {
                let newExpirationDate = new Date(this.props.order.cartExpiration)
                let newNow = new Date()
                let timeToExpiry = (newExpirationDate - newNow) / 60 / 1000

                this.setState({
                    timer: Math.max(Math.round(timeToExpiry), 0)
                })
            }, 60000);
        }
    }

    render() {
        const {
            ready,
            cartItems,
            addedCharges,
            currentCartValue,
            currentUser,
            initFail,
        } = this.props

        if (initFail) {
            return <Redirect to="/commande" />
        }

        if (!ready) {
            return (
                <Dimmer
                    active
                    inverted
                    style={{ height: '300px' }}
                >
                    <Loader
                        inverted
                        content="Chargement"
                    />
                </Dimmer>
            )
        }

        return (
            <div className={Styles.CartDetails}>
                <div className={Styles.cartHeader}>
                    <Header as="h3">
                        <Icon name="shopping bag" />
                        Mon panier
                    </Header>
                    {cartItems.length > 0 && this.state.timer &&
                        <div className={Styles.timerContainer}>
                            <Image src={horloge} alt="horloge" style={{ width: '30px', height: '30px' }} />
                            <p style={{ fontSize: '0.9em', textAlign: 'start', marginLeft: '10px' }}>Nous gardons vos articles au chaud pendant <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{this.state.timer} min</span></p>
                        </div>
                    }
                </div>

                <CartList
                    items={cartItems}
                    addedCharges={addedCharges}
                    currentCartValue={currentCartValue}
                    handleCartChange={this.handleCartChange}
                />

                <PersistentCheckoutBar
                    currentUser={currentUser}
                    blockCheckout={cartItems.length === 0}
                />
            </div>
        )
    }
}

export { CartDetailsBase as CartDetails }

CartDetailsBase.propTypes = {
    ready: PropTypes.bool,
    fetchInit: PropTypes.func.isRequired,
    cartItems: PropTypes.array,
    addedCharges: PropTypes.array,
    currentCartValue: PropTypes.number,
    currentUser: PropTypes.object,
    initFail: PropTypes.bool,
    updateCartItem: PropTypes.func.isRequired,
}

const CartDetails = props => (
    <ErrorBoundary page="cart-details">
        <CartDetailsBase {...props} />
    </ErrorBoundary>
)

const mapDispatchToProps = dispatch => {
    return {
        updateCartItem: params => dispatch(updateCartItem(params)),
        fetchInit: () => dispatch(fetchInit()),
        scheduleSyncOrder: () => scheduleSyncOrder(dispatch, undefined, 'check'),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartDetails)
