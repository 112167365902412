export const handleSelectSchedule = function (locale, schedule) {
    // this.props.selectGlobalOrder(schedule.globalOrder._id)
    // console.log('handleselectschedule 1) locale', locale.code)
    // console.log('handleselectschedule 2) globalorder_id', schedule.globalOrder._id)

    // Initializing switchTo in the state + redirect to 'verification-du-panier'
    this.props.switchToInit({
        locale: { ...locale, currentSchedule: schedule },
        schedule: schedule,
        globalOrder: schedule.globalOrder
    })

    // setTimeout(() => {
    //     window.location.reload()
    // }, 100)
}
