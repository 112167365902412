import { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import EventEmitter from 'events'
import ReactPixel from 'react-facebook-pixel';

import {
    clearCurrentCartExpirationMessage,
    scheduleSyncOrder,
    switchToInit,
    setSideMenuVisibility,
    handleReferralLink,
} from '../../../actions'

import AppHeader from '../Header'

import Account from '../../pages/Account'
import Order from '../../pages/Order'
import CartDetails from '../../pages/CartDetails'
import CartCorrection from '../../pages/CartCorrection'
import Checkout from '../../pages/Checkout'
import ChooseSchedule from '../../pages/ChooseSchedule'
import Login from '../../pages/Login'
import CreateAccount from '../../pages/CreateAccount'
import ResetPassword from '../../pages/ResetPassword'
import UpdateEmail from '../../pages/UpdateEmail'
import ForgotPassword from '../../pages/ForgotPassword'
import ErrorPage from '../../pages/ErrorPage'
import NotFoundPage from '../../pages/NotFound'

import CartWarnings from './CartWarnings'
import Styles from './styles.module.css'
import { CheckoutPaymentCBReturnSuccess } from '../../pages/Checkout/CheckoutPaymentCBReturnSuccess'
import { CheckoutPaymentCBReturnFailure } from '../../pages/Checkout/CheckoutPaymentCBReturnFailure'
import VersionChangeConfirm from './VersionChangeConfirm'

const LAST_BUILD_STORAGE_KEY = 'LAST_DOWNLOADED_BUILD'
const BUILD_ID = process.env.REACT_APP_BUILD_ID

const PrivateRoute = ({ component: Component, currentUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            typeof currentUser.token !== 'undefined' ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
)

const eventEmitter = new EventEmitter()

const DefaultLayoutRoute = ({ isProtected, ...props }) => {
    const wrapperClass = `AppWrapper ${Styles.App} ${props.sideMenuVisible ? 'mobileMenuOpen' : ''
        }`
    return (
        <div className={wrapperClass}>
            <AppHeader
                persistor={props.persistor}
                eventEmitter={eventEmitter}
            />

            <CartWarnings
                currentCartExpirationMessage={
                    props.currentCartExpirationMessage
                }
                clearCurrentCartExpirationMessage={
                    props.clearCurrentCartExpirationMessage
                }
                scheduleSyncOrder={props.scheduleSyncOrder}
                switchToInit={props.switchToInit}
                orderRecovery={props.orderRecovery}
            />

            {isProtected ? <PrivateRoute {...props} /> : <Route {...props} />}
        </div>
    )
}

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            versionChangeModalOpen: false,
        }

        this.setSideMenuVisibility = this.setSideMenuVisibility.bind(this)
        this.confirmVersionChange = this.confirmVersionChange.bind(this)
    }

    componentDidMount() {
        ReactPixel.init('191090086929472');
        ReactPixel.pageView();

        this.unlisten = this.props.history.listen(() => {
            ReactPixel.pageView();
        });

        const LAST_BUILD_ID = localStorage.getItem(LAST_BUILD_STORAGE_KEY)
        if (LAST_BUILD_ID !== BUILD_ID) {
            this.setState({
                versionChangeModalOpen: true,
            })
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    setSideMenuVisibility() {
        this.props.dispatch(setSideMenuVisibility(false))
    }

    confirmVersionChange() {
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem(LAST_BUILD_STORAGE_KEY, BUILD_ID)

        this.setState({
            versionChangeModalOpen: false,
        })

        window.location.reload()
    }

    render() {
        return (
            <>
                <Switch>
                    <DefaultLayoutRoute
                        exact
                        path="/login"
                        render={props => (
                            <Login
                                {...props}
                            />
                        )}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/creer-un-compte"
                        component={CreateAccount}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/reset-mot-de-passe/:reset_password_token"
                        render={props => (
                            <ResetPassword
                                {...props}
                                persistor={this.props.persistor}
                            />
                        )}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/update-email"
                        component={UpdateEmail}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/mot-de-passe-oublie"
                        component={ForgotPassword}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/cart"
                        component={CartDetails}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/verification-du-panier"
                        component={CartCorrection}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        path="/account"
                        isProtected={true}
                        component={Account}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        path="/checkout"
                        isProtected={true}
                        component={Checkout}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/choisir-un-magasin"
                        render={props => (
                            <ChooseSchedule
                                {...props}
                                eventEmitter={eventEmitter}
                                {...this.props}
                            />
                        )}
                    />

                    <DefaultLayoutRoute
                        exact
                        path="/"
                        render={props => (
                            <Redirect
                                to={{
                                    pathname: '/choisir-un-magasin',
                                    state: { from: props.location },
                                }}
                            />
                        )}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/commande"
                        render={props => (
                            <Order
                                eventEmitter={eventEmitter}
                                {...props}
                            />
                        )}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/commande/:category"
                        render={props => (
                            <Order
                                eventEmitter={eventEmitter}
                                {...props}
                            />
                        )}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/commande/:category/:family"
                        render={props => (
                            <Order
                                eventEmitter={eventEmitter}
                                {...props}
                            />
                        )}
                        {...this.props}
                    />

                    {/* all this... so we don't render the layout in the payment reply iframe... */}
                    <Route
                        exact
                        path="/iframe-success"
                        component={CheckoutPaymentCBReturnSuccess}
                    />
                    <Route
                        exact
                        path="/iframe-failure"
                        component={CheckoutPaymentCBReturnFailure}
                    />

                    <DefaultLayoutRoute
                        exact
                        path="/500"
                        component={ErrorPage}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        exact
                        path="/404"
                        component={NotFoundPage}
                        {...this.props}
                    />
                    <DefaultLayoutRoute
                        component={NotFoundPage}
                        {...this.props}
                    />
                </Switch>
                <VersionChangeConfirm versionChangeModalOpen={this.state.versionChangeModalOpen} confirmVersionChange={this.confirmVersionChange} />
            </>
        )
    }
}

const mapStateToProps = ({ currentUser, version, session, cart, orderRecovery }) => ({
    version,
    currentUser,
    sideMenuVisible: session.sideMenuVisible,
    currentCartExpirationMessage: session.currentCartExpirationMessage,
    cartIsEmpty: !cart || cart.list.length === 0,
    referralSent: !!session.referralSent,
    orderRecovery,
})

const mapDispatchToProps = dispatch => {
    return {
        clearCurrentCartExpirationMessage: () =>
            dispatch(clearCurrentCartExpirationMessage()),
        switchToInit: props => dispatch(switchToInit(props)),
        scheduleSyncOrder: () => scheduleSyncOrder(dispatch),
        handleReferralLink: code => dispatch(handleReferralLink(code)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
