import React, { useState, useCallback } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

import { mapStyle } from './mapStyle'
import { GOOGLE_API_KEY } from '../../../../../app-constants'

import MarkerImage from '../../../../../assets/marker.svg'
import SelectedMarker from '../../../../../assets/pin_selected.svg'
import PlacesAutocomplete from './PlaceAutocomplete'

const libraries = ['places']

const options = {
    styles: mapStyle,
    disableDefaultUI: true,
    zoomControl: true,
}

const MapContainer = props => {
    const {
        center,
        openPanel,
        setOpenPanel,
        cardListRef,
        handleLocaleClick,
        setSelectedLatLng,
        selectedLatLng,
        mapRef,
        setSelectedBoutique,
    } = props

    const [zoom, setZoom] = useState(11)
    const [map, setMap] = useState(null)
    const [markers, setMarkers] = useState([])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries,
    })

    const onMapClick = useCallback(e => {
        document.getElementById("combo-box-input").blur()
        setMarkers(current => [
            ...current,
            {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                time: new Date(),
            },
        ])
    }, [])

    const mapContainerStyle = (window.innerWidth > 1024 ?
        {
            height: 'calc(100vh - 106px)',
            width: '100%',
            marginTop: 0,
        }
        : window.innerWidth < 500 ?
            {
                height: 'calc(54vh - 48px)',
                width: '100%',
                marginTop: 0,
            }
            :
            {
                height: '45vh',
                width: '100%',
                marginTop: 0,
            }
    )

    const onMapLoad = useCallback(map => {
        mapRef.current = map
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const locales = props.locales && props.locales.filter(l => !l.home_delivery)

    return (
        <div>
            {isLoaded &&
                <>
                    <PlacesAutocomplete
                        mapRef={mapRef}
                        isPositionAbsolute={true}
                        setSelectedLatLng={setSelectedLatLng}
                        locales={locales}
                        cardListRef={cardListRef}
                    />

                    <GoogleMap
                        id="map"
                        mapContainerStyle={mapContainerStyle}
                        zoom={zoom}
                        center={center}
                        styles={mapStyle}
                        options={options}
                        onClick={onMapClick}
                        onLoad={onMapLoad}
                        onUnmount={onUnmount}
                    >
                        {locales.map((locale, index) => {
                            return (
                                <Marker
                                    key={`${locale.lat}-${locale.lng}`}
                                    position={{
                                        lat: locale.geolocation.lat,
                                        lng: locale.geolocation.lng,
                                    }}
                                    title={`marqueur ${locales[index].name}`}
                                    onClick={() => {
                                        document.getElementById("combo-box-input").blur()
                                        const newArr = new Array(locales.length).fill(false)

                                        if (openPanel[index] === true) {
                                            newArr[index] = false
                                            setOpenPanel(newArr)
                                        } else {
                                            newArr[index] = true
                                            setOpenPanel(newArr)
                                        }

                                        setSelectedBoutique(locale)

                                        if (cardListRef && cardListRef.current) {
                                            if (window.innerWidth > 1000) {
                                                cardListRef.current.children[index + 1].scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'start',
                                                })
                                                // Assuming your map component exposes a method to pan to the location
                                                mapRef.current.setZoom(11)
                                                setTimeout(() => {
                                                    mapRef.current.panTo({
                                                        lat: locale.geolocation.lat,
                                                        lng: locale.geolocation.lng,
                                                    })
                                                    mapRef.current.setZoom(12) // You can adjust the zoom level as needed
                                                }, 500)
                                            }
                                            else {
                                                let containerTop = cardListRef.current.children[
                                                    index + 1
                                                ].getBoundingClientRect()

                                                window.scrollTo(
                                                    {
                                                        top: containerTop.top + window.scrollY - 78,
                                                        left: 0,
                                                        behavior: 'smooth',
                                                    })
                                            }
                                        }
                                    }}
                                    icon={{
                                        url:
                                            openPanel[index] === true
                                                ? SelectedMarker
                                                : MarkerImage,
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: locale.partner
                                            ? new window.google.maps.Point(16, 16)
                                            : new window.google.maps.Point(25, 25),
                                        scaledSize: locale.partner
                                            ? new window.google.maps.Size(42, 42)
                                            : new window.google.maps.Size(60, 60),
                                    }}
                                />
                            )
                        })}
                        {selectedLatLng !== null &&
                            <Marker
                                position={{
                                    lat: selectedLatLng.lat,
                                    lng: selectedLatLng.lng,
                                }}
                            />
                        }
                    </GoogleMap>
                </>
            }
        </div>
    )
}

export default MapContainer
