import React from 'react'
import { Message, Icon } from 'semantic-ui-react'

export const AccountPageDetailsInvalidAddressWarning = ({
    showInvalidAddressWarning,
}) => (
    <Message
        icon
        size="small"
        warning
        visible={showInvalidAddressWarning}
    >
        <Icon name="warning sign" />

        <Message.Content>
            <Message.Header>Adresse de livraison invalide</Message.Header>

            <Message.List>
                <Message.Item>
                    Les champs <u>téléphone</u>, <u>rue</u>, <u>ville</u> et{' '}
                    <u>code postal</u> sont requis.
                </Message.Item>
                <Message.Item>
                    Le code postal doit être dans Paris intramuros.
                </Message.Item>
            </Message.List>
        </Message.Content>
    </Message>
)
