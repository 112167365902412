import { Button, Icon } from 'semantic-ui-react'
import React, { Component } from 'react'

import { Redirect } from 'react-router-dom'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'

class CountDown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dayOrderEnd: formatDate.run(
                this.props.currentGlobalOrder.order_end,
                'YYYY-MM-DD'
            ),
            hoursOrderEnd: formatDate.run(
                this.props.currentGlobalOrder.order_end,
                'HH:mm'
            ),
            today: this.props.currentDate
                ? new Date(this.props.currentDate)
                : new Date(),
        }
        this.refreshCountDown = this.refreshCountDown.bind(this)
    }

    getCtdownDiff(orderEnd, orderToday) {
        let dateEnd = new Date(orderEnd)
        let dateToday = new Date(orderToday)

        let timeDiff = dateEnd.getTime() - dateToday.getTime()
        let daysDiff = timeDiff / (1000 * 3600 * 24)

        return daysDiff
    }

    getTabDays(date) {
        let tabDays = date.split('-')
        return tabDays
    }

    getTabHours(date) {
        let tabHours = date.split(':')
        return tabHours
    }

    /** This function return a correct format date
     * to handle Safari display due to
     *   ISO 8601.
     **/
    getDateOrderEnd(days, hours) {
        let tabDays = this.getTabDays(days)
        let tabHours = this.getTabHours(hours)

        return new Date(
            tabDays[0],
            tabDays[1] - 1,
            tabDays[2],
            tabHours[0],
            tabHours[1]
        )
    }

    getHoursToTimeDiff(value) {
        return Math.trunc(value * 24)
    }

    getMinutesToTimeDiff(value, hours) {
        return Math.trunc((value * 24 - hours) * 60)
    }

    refreshCountDown() {
        this.setState({
            today: new Date(),
        })
    }

    render() {
        // Handle Safari display
        let dateOrderEnd = this.getDateOrderEnd(
            this.state.dayOrderEnd,
            this.state.hoursOrderEnd
        )

        // number of days between the currentglobalOrder.orderEnd and today
        let diff = this.getCtdownDiff(dateOrderEnd, this.state.today)

        // 1 represent the last 24h REMETTRE "&& diff > 0"
        if (diff <= 1) {
            let hours = this.getHoursToTimeDiff(diff)
            let minutes = this.getMinutesToTimeDiff(diff, hours)
            return (
                <div
                    style={{ color: '#DFAF2C' }}
                    data-testid="countdown"
                >
                    <Icon name="warning circle" />
                    Clôture dans {hours === 0 ? '00' : hours}h
                    {minutes === 0 ? '00' : minutes}
                    <Button
                        style={{ background: 'none' }}
                        title="rafraîchir"
                        className="ui icon button"
                        onClick={this.refreshCountDown}
                    >
                        <i className="tiny circular sync link icon"></i>
                    </Button>
                </div>
            )
        }
        return ''
    }
}

export default CountDown
