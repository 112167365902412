import React from 'react'
import { Button } from 'semantic-ui-react'
import Responsive from "../../../ui/Responsive"
import { Link } from 'react-router-dom'

import Styles from './styles.module.css'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../app-constants'

export const PersistentCheckoutBar = ({ currentUser, blockCheckout }) => {
    return (
        <div className={Styles.PersistentCheckoutBar}>
            <Link to="/commande">
                <Responsive
                    maxWidth={MAX_WIDTH.SM}
                    as={Button}
                    size="medium"
                    content="Je reviens à la vente"
                    primary
                    basic
                    icon="left arrow"
                    labelPosition="left"
                    className={Styles.ExpandingButton}
                />
                <Responsive
                    minWidth={MIN_WIDTH.MD}
                    as={Button}
                    size="huge"
                    content="Je reviens à la vente"
                    primary
                    basic
                    icon="left arrow"
                    labelPosition="left"
                    className={Styles.ExpandingButton}
                />
            </Link>

            {!blockCheckout && (
                <Link
                    to={currentUser.loggedin
                        ? '/checkout'
                        : '/login?goto=/checkout'
                    }
                >
                    <Responsive
                        as={Button}
                        maxWidth={MAX_WIDTH.SM}
                        size="medium"
                        content="Je valide ma commande"
                        primary
                        icon="right arrow"
                        labelPosition="right"
                        className={Styles.ExpandingButton}
                    />
                    <Responsive
                        as={Button}
                        minWidth={MIN_WIDTH.MD}
                        size="huge"
                        content="Je valide ma commande"
                        primary
                        icon="right arrow"
                        labelPosition="right"
                        className={Styles.ExpandingButton}
                    />
                </Link>
            )}
        </div>
    )
}
