import React, { Component } from 'react'
import {
    Form,
    Message,
    Icon,
    Header,
    Button,
    Popup,
    Dimmer,
    Loader,
    Checkbox,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import pickProperties from '@kelbongoo/shared-methods/utils/pickProperties'
import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'

import {
    forgotPassword,
    sendUpdateConsumerEmailConfirmation,
    updateConsumerData,
    clearError,
} from '../../../../actions'

import InfoModal from '../../../ui/InfoModal'
import ErrorBoundary from '../../../layout/ErrorBoundary'

import { AddressHeader } from './AddressHeader'
import { AccountPageDetailsUpdateEmailModal } from './AccountPageDetailsUpdateEmailModal'
import { AccountPageDetailsInvalidAddressWarning } from './AccountPageDetailsInvalidAddressWarning'
import {
    handleChange,
    handleSubmit,
    onResetPassword,
    isUrlDeliveryAddressInvalid,
    formatErrorText,
    userKeys,
} from './helpers'
import { Redirect } from 'react-router'
import Styles from './styles.module.css'
// import PaymentMethodsLists from "./PaymentMethodsLists";

/**
 * a valid consumer state must have firstname and lastname,
 * so if (for whatever reason) one or the other is
 * currently missing, we force the user to set their values
 * then we enforce a valid telephone and a non-empty update
 */

// const paymentMethodsMockData = [
//   {
//     id: 1,
//     cardNumber: 2547,
//     expirationDate: "09/2023",
//   },
//   {
//     id: 2,
//     cardNumber: 6587,
//     expirationDate: "10/2024",
//   },
//   {
//     id: 3,
//     cardNumber: 8521,
//     expirationDate: "01/2024",
//   },
// ];
export class DetailsPage extends Component {
    constructor(props) {
        super(props)

        let checkoutInvalidDeliveryAddressRedirect =
            isUrlDeliveryAddressInvalid(props)

        let currentUser = props.currentUser || {}

        this.state = {
            validated: false,
            loading: false,
            init: true,
            resetPasswordLoading: false,
            checkoutInvalidDeliveryAddressRedirect,
            validationRequired:
                typeof props.validationRequired !== 'undefined'
                    ? props.validationRequired
                    : false,
            ...currentUser,
        }

        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
        this.handleResetPasswordSuccessDismiss =
            this.handleResetPasswordSuccessDismiss.bind(this)
        this.handleFormSuccessDismiss = this.handleFormSuccessDismiss.bind(this)
        this.handleFormErrorDismiss = this.handleFormErrorDismiss.bind(this)
        this.handleChange = handleChange.bind(this)
        this.handleSubmit = handleSubmit.bind(this)
        this.onResetPassword = onResetPassword.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentUser.loading === false) {
            if (!!state.init) {
                return {
                    ...pickProperties.run(state, ['email', ...userKeys]),
                    ...pickProperties.run(props.currentUser, [
                        'email',
                        ...userKeys,
                    ]),
                    init: false,
                    loading: false,
                }
            } else {
                return state
            }
        }
        return null
    }

    handleResetPasswordSuccessDismiss() {
        this.setState({ showResetPasswordSuccess: false })
    }

    handleFormSuccessDismiss() {
        this.setState({ showFormSuccess: false })
    }

    handleFormErrorDismiss() {
        this.setState({ showFormError: false })
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    render() {
        const {
            telephone,
            email,
            firstname,
            lastname,
            default_locale,
            // send_closing_email,
            send_launch_email,
            address_street,
            address_city,
            address_zip,
            address_code,
            address_instructions,
            has_valid_delivery_address,
            checkoutInvalidDeliveryAddressRedirect,
        } = this.state

        const showInvalidAddressWarning =
            checkoutInvalidDeliveryAddressRedirect &&
            !has_valid_delivery_address

        const {
            locales,
            currentUser,
            sendUpdateEmailConfirmation,
            serverError,
        } = this.props

        const localeOptions = locales
            .map(l => ({
                key: l.code,
                value: l.code,
                text: l.name,
            }))
            .slice()
            .sort(sortByProps(['text']))

        if (this.state.validationRequired) {
            return (
                <div>
                    <Form>
                        <Header
                            as="h3"
                            textAlign="center"
                            icon
                        >
                            <Icon name="user plus" />
                            Validation email requise!
                        </Header>
                    </Form>
                </div>
            )
        }

        if (this.state.redirectCheckout) {
            return (
                <Redirect
                    to="/checkout"
                    push
                />
            )
        }

        return (
            <Dimmer.Dimmable dimmed={this.state.loading}>
                <Dimmer
                    active={this.state.loading}
                    inverted
                >
                    <Loader size="medium">Chargement</Loader>
                </Dimmer>

                <InfoModal
                    show={!!serverError}
                    icon="info circle"
                    header=""
                    body={serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />
                <InfoModal
                    show={!!this.state.showFormError}
                    onClose={this.handleFormErrorDismiss}
                    isError={true}
                    header={"Merci d'entrer une adresse à Paris"}
                    body={
                        'La livraison est pour le moment valable uniquement à Paris intramuros.'
                    }
                />
                <InfoModal
                    show={!!this.state.showFormSuccess}
                    onClose={this.handleFormSuccessDismiss}
                    isSuccess={true}
                />
                <InfoModal
                    show={!!this.state.showResetPasswordSuccess}
                    body="Un mail vient de vous être envoyé avec un lien pour changer votre mot de passe."
                    onClose={this.handleResetPasswordSuccessDismiss}
                    isInfo={true}
                />

                <div className={Styles.Container}>
                    <Form>
                        {currentUser.pro && (
                            <Message
                                size="mini"
                                icon="info circle"
                                header="Vous avez un compte professionnel"
                                style={{ marginBottom: '2em' }}
                            />
                        )}

                        <div className={Styles.Module}>
                            <div className={Styles.Title}>Mon profil :</div>
                            <Form.Field
                                required
                                style={{ margin: '1em 0' }}
                            >
                                <label htmlFor="lastname">Nom</label>
                                <input
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Nom"
                                    value={lastname || ''}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Form.Field>

                            <Form.Field
                                required
                                style={{ margin: '1em 0' }}
                            >
                                <label htmlFor="firstname">Pr&eacute;nom</label>
                                <input
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Prénom"
                                    value={firstname || ''}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Form.Field>

                            <div className={Styles.Align}>
                                <Form.Field>
                                    <label htmlFor="email">Adresse mail</label>
                                    <AccountPageDetailsUpdateEmailModal
                                        email={email}
                                        sendUpdateEmailConfirmation={
                                            sendUpdateEmailConfirmation
                                        }
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label htmlFor="password">Mot de passe</label>
                                    <Button
                                        type="button"
                                        loading={
                                            this.state.resetPasswordLoading
                                        }
                                        content="Changer mon mot de passe"
                                        onClick={this.onResetPassword.bind(
                                            this
                                        )}
                                    />
                                </Form.Field>
                            </div>

                            <Form.Field>
                                <label htmlFor="telephone">
                                    T&eacute;l&eacute;phone &nbsp;
                                    <Popup
                                        trigger={
                                            <Icon name="question circle" />
                                        }
                                        content="Si vous choisissez de régler votre commande lors du retrait,
                    nous aurons besoin de votre numéro de téléphone"
                                    />
                                </label>
                                <input
                                    name="telephone"
                                    id="telephone"
                                    placeholder="Numéro de téléphone"
                                    value={telephone || ''}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Form.Field>
                        </div>

                        <div className={Styles.Module}>
                            <div className={Styles.Title}>
                                Mes préférences :
                            </div>
                            <Form.Field>
                                <label style={{ marginTop: '15px' }}>
                                    Boutique par d&eacute;faut &nbsp;
                                    <Popup
                                        trigger={
                                            <Icon name="question circle" />
                                        }
                                        content="La boutique où vous commandez habituellement.
                      Vous pouvez toujours choisir une autre boutique si vous voulez !"
                                    />
                                </label>
                                <Form.Select
                                    fluid
                                    options={localeOptions}
                                    placeholder="Choisir une boutique"
                                    name="default_locale"
                                    value={default_locale || ''}
                                    onChange={this.handleChange.bind(this)}
                                    data-testid="default_locale"
                                />
                            </Form.Field>

                            <Form.Field style={{ margin: '2em 0 1em' }}>
                                <Checkbox
                                    toggle
                                    label="Rappel à l'ouverture des commandes"
                                    name="send_launch_email"
                                    id="send_launch_email"
                                    onChange={this.handleChange.bind(this)}
                                    checked={send_launch_email || false}
                                    data-testid="send_launch_email"
                                />
                            </Form.Field>

                            {/*              <Form.Field style={{ margin: "1em 0" }}>
                <Checkbox
                  toggle
                  label="Rappel avant la fermeture de chaque commande"
                  name="send_closing_email"
                  onChange={this.handleChange.bind(this)}
                  checked={send_closing_email || false}
                  data-testid="send_closing_email"
                />
              </Form.Field>*/}
                        </div>

                        <div className={Styles.Module}>
                            <div className={Styles.Title}>Mon adresse :</div>

                            {/* Delivery address group */}
                            <AccountPageDetailsInvalidAddressWarning
                                showInvalidAddressWarning={
                                    showInvalidAddressWarning
                                }
                            />

                            <AddressHeader
                                has_valid_delivery_address={
                                    has_valid_delivery_address
                                }
                            />

                            <div className={Styles.Align}>
                                <Form.Field className={Styles.Street}>
                                    <label htmlFor="address_street">Rue</label>
                                    <input
                                        name="address_street"
                                        id="address_street"
                                        placeholder="123 avenue Jean Jaures"
                                        onChange={this.handleChange.bind(this)}
                                        value={address_street}
                                    />
                                </Form.Field>
                                <Form.Field className={Styles.City}>
                                    <label htmlFor="address_city">Ville</label>
                                    <input
                                        name="address_city"
                                        id="address_city"
                                        placeholder="Paris"
                                        onChange={this.handleChange.bind(this)}
                                        value={address_city}
                                    />
                                </Form.Field>
                                <Form.Field className={Styles.PostalCode}>
                                    <label htmlFor="address_zip">
                                        Code postal
                                    </label>
                                    <input
                                        name="address_zip"
                                        id="address_zip"
                                        placeholder="75019"
                                        onChange={this.handleChange.bind(this)}
                                        value={address_zip}
                                    />
                                </Form.Field>
                            </div>
                            <Form.Field style={{ marginBottom: '1em' }}>
                                <label htmlFor="address_code">
                                    Code immeuble
                                </label>
                                <input
                                    name="address_code"
                                    id="address_code"
                                    placeholder="1234A"
                                    onChange={this.handleChange.bind(this)}
                                    value={address_code}
                                />
                            </Form.Field>
                            <Form.Field style={{ marginBottom: '1em' }}>
                                <label htmlFor="address_instructions">
                                    Instructions &agrave; donner au livreur
                                </label>
                                <textarea
                                    name="address_instructions"
                                    id="address_instructions"
                                    rows={8}
                                    placeholder="Instructions supplémentaires (200 caracteres max)"
                                    onChange={this.handleChange.bind(this)}
                                    value={address_instructions}
                                    className={Styles.Textarea}
                                />
                                <label htmlFor="address_instructions">
                                    Livraison en pied d'immeuble. Exception
                                    possible pour les personnes à mobilité
                                    réduite, auquel cas merci de préciser
                                    ci-dessus votre souhait d'être livré à la
                                    porte.
                                </label>
                            </Form.Field>
                        </div>

                        {/*            {* paymentMethodsMockData.length > 0 && (
              <div className={Styles.Module}>
                <div className={Styles.Title}>Mes cartes enregistrées :</div>
                <div className={Styles.PaymentMethodsList}>
                  <PaymentMethodsLists
                    paymentMethodsData={paymentMethodsMockData}
                  />
                </div>
              </div>
            )*}*/}

                        <div className={Styles.Module}>
                            <div className={Styles.ActionPanel}>
                                <Button
                                    secondary
                                    disabled={
                                        this.state
                                            .checkoutInvalidDeliveryAddressRedirect
                                            ? true
                                            : !!this.state.validated
                                    }
                                    content={'Retour à la vente'}
                                    icon="shopping basket"
                                    href="/commande"
                                />

                                <Form.Field>
                                    <Button
                                        primary
                                        disabled={!this.state.validated}
                                        content={
                                            this.state
                                                .checkoutInvalidDeliveryAddressRedirect
                                                ? 'Valider et procéder au paiement'
                                                : 'Valider'
                                        }
                                        icon="checkmark"
                                        onClick={this.handleSubmit.bind(this)}
                                    />
                                </Form.Field>
                            </div>
                        </div>
                    </Form>
                </div>
            </Dimmer.Dimmable>
        )
    }
}

DetailsPage.propTypes = {
    currentUser: PropTypes.object.isRequired,
    locales: PropTypes.arrayOf(PropTypes.object).isRequired,
    serverError: PropTypes.string,
    sendUpdateEmailConfirmation: PropTypes.func.isRequired,
    updateParams: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    clearServerError: PropTypes.func.isRequired,
}

const AccountPageDetails = ({
    currentUser,
    locales,
    serverError = '',
    sendUpdateEmailConfirmation,
    updateParams,
    onResetPassword,
    clearServerError,
    location,
}) => (
    <ErrorBoundary page="account-page-details">
        <DetailsPage
            currentUser={currentUser}
            locales={locales}
            serverError={serverError}
            sendUpdateEmailConfirmation={sendUpdateEmailConfirmation}
            updateParams={updateParams}
            onResetPassword={onResetPassword}
            clearServerError={clearServerError}
            location={location}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, localeList, locales }) => {
    return {
        currentUser,
        locales: localeList
            .filter(
                code =>
                    locales[code] &&
                    !locales[code].private &&
                    locales[code].code !== 'PRO'
            )
            .map(code => locales[code]),
        serverError: !!currentUser.error && formatErrorText(currentUser.error),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUpdateEmailConfirmation: email =>
            dispatch(sendUpdateConsumerEmailConfirmation(email)),
        updateParams: params => dispatch(updateConsumerData(params)),
        onResetPassword: params => dispatch(forgotPassword(params)),
        clearServerError: () => dispatch(clearError('CURRENT_USER')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageDetails)
