import pickProperties from '@kelbongoo/shared-methods/utils/pickProperties'

const signupFields = [
    'firstname',
    'lastname',
    'email',
    'default_locale',
    'signup_reason',
    'telephone',
    'address_street',
    'address_city',
    'address_zip',
    'address_code',
    'address_instructions',
]

export const handleSubmit = function (event) {
    event.preventDefault()

    if (this.props.currentUser.loading) {
        return
    }

    if (
        this.state.telephone &&
        this.state.telephone !== this.state.telephone_confirm
    ) {
        this.setState({ error: true })
        return
    }

    const payload = pickProperties.run(this.state, signupFields)

    this.setState({ error: false })

    return this.props
        .createConsumer(payload)
        .then(result => {
            if (result.message === 'Consumer created successfully') {
                // gtm registration successful
                window.dataLayer &&
                    window.dataLayer.push({
                        event: 'sign_up',
                        eventProps: {
                            boutique: this.state.default_locale,
                        },
                    })

                return this.setState({
                    validationRequired: true,
                })
            } else {
                // return handleError(this.setState, result)

                // gtm registration not successful
                window.dataLayer &&
                    window.dataLayer.push({
                        event: 'sign_up_error',
                        eventProps: {
                            error: `${JSON.stringify(result)}`
                        }
                    })

                return this.setState({
                    error: `${JSON.stringify(result)}`,
                })
            }
        })
        .catch(err => {
            // gtm registration not successful
            window.dataLayer &&
                window.dataLayer.push({
                    event: 'sign_up_error',
                    eventProps: {
                        error: `${JSON.stringify(err.message)}`,
                    }
                })

            return this.setState({
                error: `${JSON.stringify(err.message)}`,
            })
        })
}
