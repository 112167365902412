import * as Types from '../../types'

/**
 * the consumer selects a locale
 * should set a reload of init, set the current locale in the store, and if there is only one
 * active globalorder, it should set this as the active globalorder
 * in case the store has a cart linked to another globalorder_id, the cart should be emptied
 * in case the orderreservation is expired, the cart should also be emptied
 * @param {string} locale - the selected locale
 */

export const selectLocale = ({ locale }) => {
    return (dispatch, getState) => {
        // console.log('setting current locale at ', locale.code)
        dispatch({
            type: Types.SET_CURRENT_LOCALE,
            locale,
        })
    }
}
