import {CONFIRM_ORDER_FAIL, GLOBAL_ORDER_ALREADY_CLOSED} from "../../types";

export const failureCallback = async ({response, dispatch}) => {
    switch (response.body.type) {
        case 'GLOBAL_ORDER_ALREADY_CLOSED':
            dispatch({type: GLOBAL_ORDER_ALREADY_CLOSED})
            break;
        default:
            dispatch({ type: CONFIRM_ORDER_FAIL, ...response.body})
            break;
    }
    return false;
}