import pickProperties from '@kelbongoo/shared-methods/utils/pickProperties'
import * as Types from '../../types'
import { wrapClientApiCall } from '../../../utils'

const updateProps = [
    'firstname',
    'lastname',
    'default_locale',
    'send_launch_email',
    // 'send_closing_email',
    'telephone',
    'address_city',
    'address_street',
    'address_zip',
    'address_code',
    'address_instructions',
    'has_valid_delivery_address',
]

export const updateConsumerData = params =>
    wrapClientApiCall({
        actionRoot: 'UPDATE_CONSUMER_DATA',
        method: 'POST',
        url: `api/consumer/update`,
        body: pickProperties.run(params, updateProps),
        hasJsonResponse: true,
        successCallback({ data, dispatch }) {
            if (data.updatedDoc) {
                dispatch({
                    type: Types.UPDATE_CONSUMER_DATA_SUCCESS,
                    params: pickProperties.run(data.updatedDoc, updateProps),
                })

                return true
            }
        },
        failureCallback() {
            return false
        },
    })
