import HELPER from '@kelbongoo/shared-methods/orders/collection'

export class Cart {
    constructor({
        // from stored cart
        list = [],
        quantities = {}, // copied from store's cart.items { id: quantity }

        // from store products
        products = {},
        // from store available
        available = {},
        isLocaleHomeDelivery = false,
        currentLocale,
    }) {
        // takes a list and a dictionary

        const items = list.map(id => ({
            ...products[id],
            quantity: quantities[id],
            available: available[id],
        }))

        let added_charges = []

        if (isLocaleHomeDelivery) {
            let addedCharges = HELPER.buildAddedCharges({
                items,
                locale: currentLocale,
                handleError: () => {},
            })

            added_charges = [...added_charges, ...addedCharges]
        }

        Object.assign(this, {
            list,
            quantities,
            items,
            added_charges,
            products,
            available,
        })
    }

    getTotalTVA() {
        return this.getTotalTTC() - this.getTotalHT()
    }

    getTotalHT() {
        let itemsTotal = this.items.reduce((acc, item) => {
            acc += Math.round(
                (item.consumer_price * item.quantity) / ((100 + item.tva) / 100)
            )
            return acc
        }, 0)

        let chargesTotal = this.added_charges.reduce(
            (a, i) => (a += i.amount_ht),
            0
        )

        return itemsTotal === 0 ? 0 : itemsTotal + chargesTotal
    }

    getTotalTTC() {
        let itemsTotal = this.items.reduce((acc, item) => {
            acc += item.consumer_price * item.quantity
            return acc
        }, 0)
        let chargesTotal = this.added_charges.reduce(
            (a, i) => (a += i.amount),
            0
        )

        return itemsTotal === 0 ? 0 : itemsTotal + chargesTotal
    }

    getCreditUsed(current_credit = 0) {
        return Math.min(this.getTotalTTC(), current_credit)
    }

    getTotalQuantity() {
        return Object.values(this.quantities).reduce((t, q) => (t += q), 0)
    }

    getLastNItems(n) {
        return this.items.slice(-n)
    }

    getRemainingCount(n) {
        return this.items.length - this.items.slice(-n).length
    }
}
