import React from 'react'
import PropTypes from 'prop-types'
import { Header, Icon } from 'semantic-ui-react'

import CountDown from '../Countdown'

import Styles from './styles.module.css'

/**
 * subheader underneath global header,
 * shows current category/family and sale closure countdown
 */

export const PersistentContextBar = ({
    category,
    currentDate,
    currentGlobalOrder,
    currentLocale,
    family,
    setSideMenuVisibility,
    sideMenuVisible,
}) => {
    const distributionDate =
        currentLocale &&
        currentLocale.currentSchedule &&
        currentLocale.currentSchedule.day
    const distributionHours =
        currentLocale &&
        currentLocale.currentSchedule &&
        currentLocale.currentSchedule.time
    const defaultHeader = `${currentLocale.name}, le ${distributionDate} (${distributionHours})`
    const backgroundTitle =
        currentGlobalOrder &&
            currentGlobalOrder.background &&
            currentGlobalOrder.title
            ? `${currentGlobalOrder.title} ${distributionHours}`
            : undefined

    return (
        <div
            className={Styles.MainView}
            data-testid="persistent-context-container"
        >
            <Icon
                name="bars"
                size="big"
                color="green"
                className={Styles.Icon}
                id='menu-button'
                onClick={() =>
                    setSideMenuVisibility({ visible: !sideMenuVisible })
                }
                inverted={sideMenuVisible}
            />

            <div className={Styles.Title}>
                <Header
                    as="h2"
                    color="green"
                    className={Styles.InlineHeader}
                >
                    {typeof backgroundTitle !== 'undefined'
                        ? backgroundTitle
                        : family && category
                            ? category.name.toUpperCase()
                            : defaultHeader}

                    {currentGlobalOrder && currentGlobalOrder._id ? (
                        <CountDown
                            currentGlobalOrder={currentGlobalOrder}
                            currentDate={currentDate}
                        />
                    ) : null}

                    {family && category && (
                        <Header.Subheader>{family.name}</Header.Subheader>
                    )}
                </Header>
            </div>
        </div>
    )
}

PersistentContextBar.propTypes = {
    category: PropTypes.object,
    currentDate: PropTypes.instanceOf(Date),
    currentGlobalOrder: PropTypes.object,
    currentLocale: PropTypes.object.isRequired,
    family: PropTypes.object,
    setSideMenuVisibility: PropTypes.func.isRequired,
    sideMenuVisible: PropTypes.bool.isRequired,
}
