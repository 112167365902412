import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import Responsive from "../../../../ui/Responsive"

import Styles from './styles.module.css'

export const PersistentCheckoutBar = ({ currentLocale }) => (
    <div className={Styles.PersistentCheckoutBar}>
        {currentLocale.home_delivery && (
            <div className={Styles.ConditionsOuter}>
                <div
                    className={Styles.ConditionsInner}
                    title="livdom-conditions"
                >
                    * En cliquant sur "R&eacute;gler ma commande", je
                    d&eacute;clare que les informations communiqu&eacute;es sont
                    correctes et compl&egrave;tes afin que le livreur puisse
                    livrer la commande
                </div>
            </div>
        )}

        <Link to="/cart">
            <Responsive
                maxWidth={MAX_WIDTH.SM}
                as={Button}
                size="medium"
                content="Je reviens au panier"
                primary
                basic
                icon="left arrow"
                labelPosition="left"
                className={`${Styles.ExpandingButton} ${Styles.SmallCartButton}`}
            />
            <Responsive
                minWidth={MIN_WIDTH.MD}
                as={Button}
                size="huge"
                content="Je reviens au panier"
                primary
                basic
                icon="left arrow"
                labelPosition="left"
                className={Styles.ExpandingButton}
            />
        </Link>

        <Link to="/checkout/reglement">
            <Responsive
                maxWidth={MAX_WIDTH.SM}
                as={Button}
                content="Je règle ma commande"
                primary
                labelPosition="right"
                size="medium"
                icon="right arrow"
                className={Styles.ExpandingButton}
            />
            <Responsive
                minWidth={MIN_WIDTH.MD}
                as={Button}
                content="Je règle ma commande"
                primary
                labelPosition="right"
                size="huge"
                icon="right arrow"
                className={Styles.ExpandingButton}
            />
        </Link>
    </div>
)
