import React from 'react'
import { Header, Icon, Grid } from 'semantic-ui-react'

const ErrorPage = () => (
    <div style={{ width: '100%', textAlign: 'center', paddingTop: '5em' }}>
        <Grid
            verticalAlign="middle"
            columns={4}
            centered
        >
            <Header
                as="h2"
                icon
            >
                <Icon name="emergency" />
                Erreur serveur
                <Header.Subheader>Il y a eu une erreur.</Header.Subheader>
            </Header>
        </Grid>
    </div>
)

export default ErrorPage
