import React from 'react'
import PropTypes from 'prop-types'
// Config
import { configPaymentMethod } from './Config'

// Components
import { Icon } from 'semantic-ui-react'
import Paymentmethod from './PaymentMethod'

// Styles
import Styles from './styles.module.css'

const CheckoutPaymentConfirmationSelector = ({
    paymentType,
    handlePaymentTypeChange,
    blockInStorePayment = false,
    blockOrderChanges = false,
    isHomeDelivery = false,
    showOfflinePayment = true,
}) => {
    let paymentCBTextTop = blockOrderChanges ? (
        <div>
            <p>
                Afin que nos producteurs puissent anticiper au mieux les
                commandes et les quantit&eacute;s, les commandes pass&eacute;es
                sont fermes et non annulables.
            </p>
            <p>
                Le paiement se fait en ligne uniquement, et vous serez
                d&eacute;bit&eacute;s imm&eacute;diatement (et non &agrave;
                r&eacute;ception des produits comme pour les commandes
                classiques).
            </p>
            <p>
                Merci pour votre compr&eacute;hension, pour le confort de
                travail de nos producteurs &nbsp;!
            </p>
        </div>
    ) : (
        <p>
            Le Paiement en ligne s&eacute;curis&eacute; est un{' '}
            <u>pr&eacute;-paiement</u>: le montant est bloqu&eacute; tout de
            suite mais n'est d&eacute;bit&eacute; que le lendemain de la{' '}
            {isHomeDelivery ? 'livraison' : 'distribution'}. Le montant
            d&eacute;bit&eacute; est automatiquement ajust&eacute; &agrave; la
            baisse si certains articles n'&eacute;taient pas disponibles le jour{' '}
            {isHomeDelivery ? 'de la livraison' : 'du retrait'}.
        </p>
    )

    return (
        <div className={Styles.Container}>
            <div className={Styles.PaymentMethodContainer}>
                {configPaymentMethod.map((paymentConfig, id) =>
                    paymentConfig.value === 'CB' ? (
                        <Paymentmethod
                            key={id}
                            title={paymentConfig.id}
                            config={paymentConfig}
                            handlePaymentTypeChange={handlePaymentTypeChange}
                            paymentType={paymentType}
                        />
                    ) : showOfflinePayment &&
                      !blockInStorePayment &&
                      !isHomeDelivery ? (
                        <Paymentmethod
                            key={id}
                            title={paymentConfig.id}
                            config={paymentConfig}
                            handlePaymentTypeChange={handlePaymentTypeChange}
                            paymentType={paymentType}
                        />
                    ) : null
                )}
            </div>

            <div className={Styles.InfoPanel}>
                <Icon
                    name="info circle"
                    className={Styles.Icon}
                    size="big"
                />
                <span>{paymentCBTextTop}</span>
            </div>
        </div>
    )
}

CheckoutPaymentConfirmationSelector.propTypes = {
    paymentType: PropTypes.string.isRequired,
    handlePaymentTypeChange: PropTypes.func.isRequired,
    blockInStorePayment: PropTypes.bool,
    blockOrderChanges: PropTypes.bool,
    isHomeDelivery: PropTypes.bool,
}

export default CheckoutPaymentConfirmationSelector
