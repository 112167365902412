import React from 'react'
import { Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import Styles from './styles.module.css'

import tomate from '../../../../assets/tomate.svg'
import logoBlanc from '../../../../assets/logo_kelbongoo2.png'

export const HeaderLogoDisplay = () => {
    return (
        <div className={Styles.logoContainer}>
            <Link
                to='/choisir-un-magasin'
                title="home-link"
            >
                <Image
                    src={logoBlanc}
                    title='logo'
                    className={Styles.logoLarge}
                    alt="large-logo"
                />
            </Link>
        </div>
    )
}
