import React from 'react'
import { List } from 'semantic-ui-react'

const getCardIconName = b => {
    var name = {
        VISA: 'cc visa',
        VISA_ELECTRON: 'cc visa',
        VPAY: 'cc visa',
        MASTERCARD: 'cc mastercard',
    }[b]
    return name || 'credit card'
}

export const CheckoutPaymentTokenItem = ({ token, handleSelection, index }) => (
    <List.Item
        key={index}
        data-testid="cb-item"
    >
        <List.Icon
            name={getCardIconName(token.brand)}
            size="large"
            verticalAlign="middle"
        />

        <List.Content>
            <List.Header
                as="a"
                onClick={() => handleSelection(token.token)}
            >
                {token.number}
            </List.Header>

            <List.Description>
                {`${token.expiryMonth} / ${token.expiryYear}`}
            </List.Description>
        </List.Content>
    </List.Item>
)
