import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader, Dimmer } from 'semantic-ui-react'

import {
    confirmOrder,
    clearError,
    fetchConsumerPaymentTokens,
} from '../../../../actions'

import ErrorBoundary from '../../../layout/ErrorBoundary'

import { CheckoutSteps } from './CheckoutSteps'
import { CheckoutPaymentConfirmationInner } from '../CheckoutPaymentConfirmationInner'
import { handleOrderConfirmation } from './helpers'
import { mapStateToProps } from './helpers'

export class CheckoutPaymentConfirmationBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmed: false,
            confirming: false,
        }
        this.handleOrderConfirmation = handleOrderConfirmation.bind(this)
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
    }

    UNSAFE_componentWillMount() {
        this.props.fetchConsumerPaymentTokens()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleErrorDismiss() {
        this.setState({
            confirming: false,
            confirmed: false,
        })
        this.props.clearServerError()
    }

    render() {
        let { confirming, confirmed, confirmed_order_id, paymentFormData } = this.state

        let {
            cartItems,
            blockInStorePayment,
            blockOrderChanges,
            clearServerError,
            globalOrderAlreadyClosed,
            isHomeDelivery,
            isOrderLocalePrivate,
            loadingPaymentTokens,
            paymentTokens,
            serverError,
        } = this.props

        if (loadingPaymentTokens) {
            return (
                <Dimmer active inverted>
                    <Loader size="large">Chargement...</Loader>
                </Dimmer>
            )
        }

        return (
            <CheckoutPaymentConfirmationInner
                blockInStorePayment={blockInStorePayment}
                blockOrderChanges={blockOrderChanges}
                clearServerError={clearServerError}
                confirmed={confirmed}
                confirming={confirming}
                cartItems={cartItems}
                globalOrderAlreadyClosed={globalOrderAlreadyClosed}
                handleConfirmation={this.handleOrderConfirmation}
                handleErrorDismiss={this.handleErrorDismiss}
                isHomeDelivery={isHomeDelivery}
                isOrderLocalePrivate={isOrderLocalePrivate}
                order_id={confirmed_order_id}
                paymentFormData={paymentFormData}
                paymentTokens={paymentTokens}
                serverError={serverError}>
                <CheckoutSteps />
            </CheckoutPaymentConfirmationInner>
        )
    }
}

const CheckoutPaymentConfirmation = props => (
    <ErrorBoundary page="checkout-payment-confirmation">
        <CheckoutPaymentConfirmationBase {...props} />
    </ErrorBoundary>
)

const mapDispatchToProps = dispatch => ({
    confirmOrder: params => dispatch(confirmOrder(params)),
    clearServerError: () => dispatch(clearError('VALIDATE_ORDER')),
    fetchConsumerPaymentTokens: () => dispatch(fetchConsumerPaymentTokens()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentConfirmation)
