export const onResetPassword = function () {
    this.setState({
        resetPasswordLoading: true,
    })

    this.props
        .onResetPassword({
            email: this.props.currentUser.email,
        })
        .then(result => {
            if (result) {
                this.setState({ showResetPasswordSuccess: true })
            }
        })
        .finally(() => {
            this.setState({
                resetPasswordLoading: false,
            })
        })
}
