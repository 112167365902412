export const addImgPrefix = (path, context) => {
    if (!path) return false
    return context === 'thumbnail'
        ? `https://cdn-products-thumbnails.kelbongoo.com/${path}`
        : context === 'detail'
        ? `https://cdn-products.kelbongoo.com/${path}`
        : context === 'boutique'
        ? `https://cdn.kelbongoo.com/boutiques_img/${path}`
        : `https://cdn.kelbongoo.com/images/${path}`
}
