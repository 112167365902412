import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { AddedChargeItem } from './AddedChargeItem'
import { CartItem } from './CartItem'

import Styles from './styles.module.css'
import { EllipsisItem } from './EllipsisItem'
import { CartPopupListFooter } from './CartPopupListFooter'

class CartPopupList extends Component {
    static propTypes = {
        items: PropTypes.array,
    }

    bumpUp({ productId, quantity }) {
        const newQuantity = quantity + 1

        this.props.handleCartChange({
            productId,
            quantity: newQuantity,
        })
    }

    bumpDown({ productId, quantity }) {
        const newQuantity = quantity - 1

        this.props.handleCartChange({
            productId,
            quantity: newQuantity,
        })
    }

    render() {
        const { items, addedCharges, notShown, handleClose, currentCartValue } =
            this.props

        return (
            <div
                className={Styles.ListContainer}
                id={this.props.id}
                title="cart-list"
            >
                <Table
                    compact="very"
                    basic="very"
                    singleLine
                    fixed
                    unstackable
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>Qté</Table.HeaderCell>
                            <Table.HeaderCell width={12}>
                                Articles
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={5}
                                textAlign="right"
                            >
                                Total
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {/* if > N */}
                        {notShown >= 1 ? (
                            <EllipsisItem notShown={notShown} />
                        ) : null}

                        {items.length > 0 ? (
                            items.map((item, index) => (
                                <CartItem
                                    key={index}
                                    item={item}
                                    bumpUp={this.bumpUp.bind(this, {
                                        productId: item.producerproduct_id,
                                        quantity: item.quantity,
                                    })}
                                    bumpDown={this.bumpDown.bind(this, {
                                        productId: item.producerproduct_id,
                                        quantity: item.quantity,
                                    })}
                                />
                            ))
                        ) : (
                            <Table.Row key="no-articles">
                                <Table.Cell colSpan="3">
                                    Aucun article dans le panier.
                                </Table.Cell>
                            </Table.Row>
                        )}

                        {items.length > 0 && addedCharges.length > 0
                            ? addedCharges.map(item => (
                                  <AddedChargeItem item={item} />
                              ))
                            : null}
                    </Table.Body>

                    {currentCartValue ? (
                        <CartPopupListFooter
                            handleClose={handleClose}
                            currentCartValue={currentCartValue}
                        />
                    ) : (
                        ''
                    )}
                </Table>
            </div>
        )
    }
}

export default CartPopupList
