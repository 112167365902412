import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'semantic-ui-react'
import  Responsive  from "../../../../ui/Responsive"
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { CartItem } from './CartItem'
import { CartAddedCharge } from './CartAddedCharge'
import { CartCredit } from './CartCredit'

import Styles from './styles.module.css'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

export class CheckoutCartInfo extends Component {
    render() {
        const { cartItems, addedCharges, creditUsed, amountToPay, welcomeCredit } = this.props

        return (
            <div className={Styles.Container}>
                <Table
                    compact="very"
                    basic
                    unstackable
                    className={Styles.Table}
                    data-testid="checkout-cart-info"
                >
                    <Table.Header>
                        <Table.Row>
                            <Responsive
                                as={Table.HeaderCell}
                                maxWidth={MAX_WIDTH.XS}
                                textAlign="center"
                                style={{ width: '40px' }}
                            >
                                Qt&eacute;
                            </Responsive>
                            <Responsive
                                as={Table.HeaderCell}
                                minWidth={MIN_WIDTH.SM}
                                textAlign="center"
                                style={{ width: '80px' }}
                            >
                                Quantit&eacute;
                            </Responsive>

                            <Table.HeaderCell>Produit</Table.HeaderCell>

                            <Responsive
                                minWidth={MIN_WIDTH.MD}
                                as={Table.HeaderCell}
                                textAlign="center"
                            >
                                Prix
                            </Responsive>

                            <Responsive
                                minWidth={MIN_WIDTH.MD}
                                as={Table.HeaderCell}
                                textAlign="center"
                            >
                                Unit&eacute;
                            </Responsive>

                            <Table.HeaderCell textAlign="center">
                                Valeur
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {cartItems.length === 0 && (
                            <Table.Row key="no-articles">
                                <Table.Cell colSpan="3">
                                    Aucun article dans le panier.
                                </Table.Cell>
                            </Table.Row>
                        )}

                        {cartItems
                            ? cartItems.map(item => (
                                  <CartItem
                                      item={item}
                                      key={item.producerproduct_id}
                                  />
                              ))
                            : ''}
                        {/* if added charges or credit used, add an empty row */}
                        {addedCharges.length > 0 || creditUsed || welcomeCredit? (
                            <Table.Row key="alt">
                                <Table.Cell colSpan="100"></Table.Cell>
                            </Table.Row>
                        ) : ('')}

                        {addedCharges ? addedCharges.map(item => <CartAddedCharge item={item} />) : ''}
                        {creditUsed && creditUsed > 0 ? <CartCredit amount={creditUsed} type="global-credit" /> : ''}
                        {welcomeCredit && welcomeCredit > 0 ? <CartCredit amount={welcomeCredit} type="welcome-credit" /> : ''}
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan="5">
                                <Button
                                    floated="right"
                                    icon
                                    color="black"
                                    label={{
                                        as: 'span',
                                        basic: true,
                                        content: formatMoney.run(
                                            amountToPay,
                                            true
                                        ),
                                    }}
                                    labelPosition="right"
                                    content="Montant à payer"
                                    size="large"
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        )
    }
}

CheckoutCartInfo.propTypes = {
    cartItems: PropTypes.array,
    addedCharges: PropTypes.array,
    creditUsed: PropTypes.number,
    amountToPay: PropTypes.number.isRequired,
}

export default CheckoutCartInfo
