import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"

import { getKgPrice } from '../../../../../utils'

import Styles from './styles.module.css'
import { MIN_WIDTH } from '../../../../../app-constants'

export const ProductInfo = ({ producer, product, toggleZoom }) => {
    return (
        <Card.Content
            className={Styles.ProductInfo}
            title="product-info"
        >
            <div className={Styles.ProductInfoInner}>
                <Card.Meta className={Styles.ProducerName}>
                    {producer.name}
                </Card.Meta>
                <div
                    onClick={toggleZoom}
                    className={Styles.LinkToZoom}
                >
                    <Card.Header className={Styles.ProductName}>
                        {product.product_name}
                    </Card.Header>
                </div>
                <Card.Meta className={Styles.Meta}>
                    {product.unit_display}
                </Card.Meta>
                {/* TODO: Gestion d'erreur sur les DLC */}
                {/* {product.dlc && (
          <Card.Meta>
            <Icon name="time" /> DLC {product.dlc} jours
          </Card.Meta>
        )} */}
                <Responsive minWidth={MIN_WIDTH.MD}>
                    <Card.Meta className={Styles.Meta}>
                        {product.unit_weight ? getKgPrice(product) : ''}
                    </Card.Meta>
                </Responsive>
            </div>
        </Card.Content>
    )
}

ProductInfo.propTypes = {
    producer: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    toggleZoom: PropTypes.func.isRequired,
}
