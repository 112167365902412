import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import ProducerList from './ProducerList'
import ProductList from './ProductList'
import MenuCategories from '../../pages/Order/MenuCategories'

import ErrorMessage from '../../ui/ErrorMessage'

import {
    fetchInit,
    fetchLocales,
    fetchConsumerCarts,
    signupForEmailNotifications,
    selectGlobalOrder,
    fetchProductsHeaderContent,
    consumerHasFailedPaymentOrder,
    onFailedPaymentOrderWarningSeen,
    setOrderContext,
    setSideMenuVisibility,
} from '../../../actions'
import ErrorBoundary from '../../layout/ErrorBoundary'

import { CallToActionModalView } from './CallToActionModalView'
import { FailedPaymentOrderModalView } from './FailedPaymentOrderModalView'
import { LocaleFullView } from './LocaleFullView'
import { OrderLoadingView } from './OrderLoadingView'
import { componentWillMount } from './componentWillMount'
import { handleUnknownGlobalOrder } from './handleUnknownGlobalOrder'
import { handleAllLocalesFull } from './handleAllLocalesFull'
import { mapStateToProps } from './mapStateToProps'

import Styles from './styles.module.css'

export class OrderPageBase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            blockVisibility: false,
            showFailedPaymentOrderModal:
                this.props.currentUser &&
                    this.props.currentUser.hasFailedPaymentOrder
                    ? this.props.currentUser.hasFailedPaymentOrder
                    : false,
        }

        this.handleRetry = this.handleRetry.bind(this)
        this.scrollTo = this.scrollTo.bind(this)
        this.loadData = this.loadData.bind(this)

        this.handleMenuClick = this.handleMenuClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)

        this.UNSAFE_componentWillMount = componentWillMount.bind(this)
        this.handleUnknownGlobalOrder = handleUnknownGlobalOrder.bind(this)
        this.handleAllLocalesFull = handleAllLocalesFull.bind(this)
    }

    loadData() {
        const loggedIn =
            this.props.currentUser && this.props.currentUser.loggedin

        this.props.fetchInit()

        if (loggedIn) {
            this.props
                .consumerHasFailedPaymentOrder(
                    this.props.currentGlobalOrder._id || undefined
                )

                .then(() => {
                    // TODO we need to ignore preflight responses here...
                    // if (!this.props.currentUser.failedPaymentOrderWarningSeen) {
                    this.setState({
                        showFailedPaymentOrderModal:
                            this.props.currentUser &&
                                this.props.currentUser.hasFailedPaymentOrder
                                ? this.props.currentUser.hasFailedPaymentOrder
                                : false,
                    })
                    // }
                })
        }
    }

    handleRetry() {
        if (this.props.locale) {
            this.props.fetchInit()
        } else {
            window.location.reload()
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick)

        /* fetch content for product header from contentful */
        const fetchHeaderContent = () => {
            try {
                return this.props.fetchProductsHeaderContent()
            } catch (err) {
                Sentry && Sentry.captureException(err)
            }
        }

        // console.log('this.props.switchTo 1) locale', this.props.switchTo ? this.props.switchTo.locale.code : undefined)
        // console.log('this.props.switchTo 2) globalorder_id', this.props.switchTo ? this.props.switchTo.globalOrder._id : undefined)
        try {
            return Promise.all([
                this.loadData(),
                fetchHeaderContent(),
            ]).then(() =>
                this.setState({
                    dataReady: true, // for testing only
                })
            )
        } catch (err) {
            const fullFetch = () =>
                this.props.fetchLocales().then(data => {
                    const activeGlobalOrders = (
                        this.props.activeGlobalOrders || data
                    ).filter(g => g.locales.includes(this.props.locale.code))

                    if (activeGlobalOrders.length === 1) {
                        const currentGlobalOrderId = activeGlobalOrders[0]._id
                        this.props.selectGlobalOrder(currentGlobalOrderId)

                        return this.loadData(currentGlobalOrderId)
                    } else {
                        return Promise.resolve()
                    }
                })

            return Promise.all([fullFetch(), fetchHeaderContent()]).then(() =>
                this.setState({
                    dataReady: true, // for testing only
                })
            )
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick)
    }

    handleOutsideClick(event) {
        if (this.props.sideMenuVisible && event.target.id !== 'menu-mobile' && event.target.id !== 'menu-button' && window.innerWidth < 500) {
            this.props.setSideMenuVisibility(false)
        }
    }

    handleMenuClick({ family, category }) {
        this.props.setOrderContext({ family, category })

        try {
            let key = family || category || 'promotions'

            if (key !== 'producteurs') {
                this.scrollTo({ key })
            }
            else {
                window.scrollTo(0, 0)
            }
            this.props.setSideMenuVisibility(false)
        } catch (e) { }
    }

    scrollTo({ key }) {
        // handles scroll in right hand lists simply using ids to find target nodes
        this.setState({ blockVisibility: true })
        setTimeout(() => {
            this.setState({ blockVisibility: false })
        })

        const node = document.getElementById(`key-${key}`)
        let containerTop = node.getBoundingClientRect()

        if (window.innerWidth > 1024) {
            window.scrollTo(
                {
                    top: containerTop.top + window.scrollY - 150,
                    left: 0,
                    behavior: 'instant',
                })
        }
        else {
            window.scrollTo(
                {
                    top: containerTop.top + window.scrollY - 145,
                    left: 0,
                    behavior: 'instant',
                })
        }
    }

    render() {
        const {
            currentGlobalOrder,
            locale,
            category,
            isLocaleFull,
            scrollKey,
            hasMultipleActiveGlobalOrders,
            isLocaleHomeDelivery,
            onFailedPaymentOrderWarningSeen,
            sideMenuVisible,
        } = this.props

        if (!locale) {
            return <Redirect to="/choisir-un-magasin" />
        }

        if (!currentGlobalOrder) {
            return this.handleUnknownGlobalOrder()
        }

        //to avoid all store full
        if (this.props.allLocalesFull) {
            return this.handleAllLocalesFull()
        }

        const showCtaModal =
            this.props.currentUser && this.props.currentUser.loggedin
                ? false
                : true

        const localeFullView = (
            <LocaleFullView
                currentLocale={locale}
                homedeliveryLocale={this.props.homedeliveryLocale}
            >
                <Link to="/choisir-un-magasin">
                    <Button
                        icon
                        labelPosition="left"
                        primary
                        size="large"
                    >
                        <Icon name="paper plane outline" />
                        Changer de boutique
                    </Button>
                </Link>
            </LocaleFullView>
        )
        const mainView = (
            <Fragment>
                {/* Left-side menu */}
                <MenuCategories
                    className={Styles.menuLeft}
                    hasMultipleActiveGlobalOrders={
                        hasMultipleActiveGlobalOrders
                    }
                    fixed
                    handleMenuClick={this.handleMenuClick}
                    scrollTo={this.scrollTo}
                    currentUser={
                        this.props.currentUser &&
                            this.props.currentUser.loggedin
                            ? this.props.currentUser
                            : false
                    }
                />

                {/* Left-side menu */}
                {sideMenuVisible && (
                    <MenuCategories
                        className={Styles.menuMobile}
                        hasMultipleActiveGlobalOrders={
                            hasMultipleActiveGlobalOrders
                        }
                        fixed
                        handleMenuClick={this.handleMenuClick}
                        scrollTo={this.scrollTo}
                        currentUser={
                            this.props.currentUser &&
                                this.props.currentUser.loggedin
                                ? this.props.currentUser
                                : false
                        }
                    />
                )}

                {/* The main view of product cards */}
                {category === 'producteurs' ? (
                    <ProducerList
                        startScroll={scrollKey}
                        blockVisibility={this.state.blockVisibility}
                    />
                ) : (
                    <ProductList
                        startScroll={scrollKey}
                        blockVisibility={this.state.blockVisibility}
                        currentGlobalOrder={this.props.currentGlobalOrder}
                    />
                )}
                {/* Modal for CTA, not displayed by default  */}
                {!isLocaleHomeDelivery ? (
                    <CallToActionModalView showModal={showCtaModal} />
                ) : (
                    ''
                )}

                {/* Modal for failed payment order, not displayed by default  */}
                {!isLocaleHomeDelivery ? (
                    <FailedPaymentOrderModalView
                        showModal={this.state.showFailedPaymentOrderModal}
                        onFailedPaymentOrderWarningSeen={
                            onFailedPaymentOrderWarningSeen
                        }
                    />
                ) : (
                    ''
                )}
            </Fragment>
        )

        const errorView = <ErrorMessage handleRetry={this.handleRetry} />

        return !this.props.initialized
            ? !!this.props.error
                ? errorView
                : OrderLoadingView()
            : isLocaleFull
                ? localeFullView
                : mainView
    }
}

OrderPageBase.propTypes = {
    initialized: PropTypes.bool.isRequired,
    locale: PropTypes.object,
    error: PropTypes.bool,
    isActiveCurrentGlobalOrder: PropTypes.bool,
    currentUser: PropTypes.object,
    scrollKey: PropTypes.string.isRequired,
    category: PropTypes.string,
    family: PropTypes.string,
    prochaineVenteStart: PropTypes.string,
    prochaineVenteStartDays: PropTypes.string,
    prochaineVenteStartHours: PropTypes.string,
    prochaineVenteDistrib: PropTypes.string,
    consumerHasFailedPaymentOrder: PropTypes.func,
}

const OrderPage = props => (
    <ErrorBoundary page="order-page">
        <OrderPageBase
            error={!!props.error}
            {...props}
        />
    </ErrorBoundary>
)

const mapDispatchToProps = dispatch => {
    return {
        validateSignupForm: ({ email, locale }) =>
            dispatch(signupForEmailNotifications({ email, locale })),
        fetchProductsHeaderContent: () =>
            dispatch(fetchProductsHeaderContent()),
        fetchInit: () => dispatch(fetchInit()),
        fetchConsumerCarts: () => dispatch(fetchConsumerCarts()),
        fetchLocales: () => dispatch(fetchLocales()),
        selectGlobalOrder: globalorder_id =>
            dispatch(selectGlobalOrder(globalorder_id)),
        consumerHasFailedPaymentOrder: globalorder_id =>
            dispatch(consumerHasFailedPaymentOrder(globalorder_id)),
        onFailedPaymentOrderWarningSeen: () =>
            dispatch(onFailedPaymentOrderWarningSeen()),
        setOrderContext: params => dispatch(setOrderContext(params)),
        setSideMenuVisibility: visible =>
            dispatch(setSideMenuVisibility(visible)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage)
