import React, { Component } from 'react'
import { Button, Dimmer, Icon, Loader, Table } from 'semantic-ui-react'
import { addImgPrefix } from '../../../utils'

import { connect } from 'react-redux'
import {
    updateCartItem,
    fetchInit,
    fetchSubstitutes,
    selectGlobalOrder,
    selectLocale,
    cancelSwitchTo,
    confirmSwitchTo,
    updateSubstituteQuantity,
} from '../../../actions'
import { mapStateToProps } from './mapStateToProps'

import Styles from './styles.module.css'
import { PersistentCartCorrectionBar } from './PersistentCartCorrectionBar/PersistentCartCorrectionBar'

/**
 *
 * 1: Display a default loader
 * 2: If the current cart has items, analyze its contents
 *  2-1 : If ALL items are available in a new store or new selected slot, redirect to order
 *  2-2 : If some items are no longer available display the basket correction view
 * 3: If the cart is empty, redirect to the command
 * 4: If Show warning = false redirect directly to the commands
 *
 */

class CartCorrectionPage extends Component {
    constructor(props) {
        super(props)
        this.handleRevertSwitch = this.handleRevertSwitch.bind(this)
        this.handleConfirmSwitch = this.handleConfirmSwitch.bind(this)
        this.handleSubstituteQuantityUpdate =
            this.handleSubstituteQuantityUpdate.bind(this)
        this.init = true
    }

    async componentDidMount() {
        const {
            fetchSubstitutes,
            cart,
            switchTo,
            currentGlobalOrder,
            orderRecovery,
            currentLocale,
        } = this.props

        // FIRST TIME >> INI
        if (!currentGlobalOrder || !currentLocale) {
        }

        // If empty cart, update to new sale (switchTo) and then pass
        if (cart.list.length === 0 && (!switchTo.cart || switchTo.cart.list.length === 0)) {
            await this.props.selectLocale({ locale: { ...switchTo.locale } })
            await this.props.selectGlobalOrder(switchTo?.schedule?.globalOrder?._id)
            window.scrollTo(0, 0)

            this.props.history.push('/commande?a=empty_cart')
            return
        }

        // If same sale than current and no orderRecovery, pass
        if ((switchTo.locale && switchTo.globalOrder) && (switchTo.locale.code === currentLocale.code && switchTo.globalOrder._id === currentGlobalOrder._id && !orderRecovery)) {
            this.props.history.push('/commande')
            window.scrollTo(0, 0)

            return
        }

        // If no switchTo, go to choisir-un-magasin
        if (!switchTo.locale && !switchTo.globalOrder && !switchTo.cart) {
            this.props.history.push('/choisir-un-magasin')
            window.scrollTo(0, 0)

            return
        }

        this.init = false

        await fetchSubstitutes(switchTo)
    }

    async handleRevertSwitch() {
        await this.props.cancelSwitchTo()
    }

    async handleConfirmSwitch(mode) {
        await this.props.confirmSwitchTo(mode)
    }

    handleSubstituteQuantityUpdate({ productId, quantity }) {
        this.props.updateSubstituteQuantity({ productId, quantity })
    }


    render() {
        const { currentLocale, switchTo, orderRecovery, currentGlobalOrder } = this.props

        const switchToLocale = {
            name: switchTo?.locale?.name,
            day: switchTo?.schedule?.day,
            time: switchTo?.schedule?.time,
        }

        // If the shopping cart analysis is not completed return a loading component
        if (this.init) {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader
                        size="large"
                        data-testid="order-loading-view"
                    >
                        Sélection du nouveau créneau de distribution...
                    </Loader>
                </Dimmer>
            )
        } else if (switchTo.fetchingSubstitutes === 'loading') {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader
                        size="large"
                        data-testid="order-loading-view"
                    >
                        Recherche des produits disponibles...
                    </Loader>
                </Dimmer>
            )
        } else if (switchTo.confirming === true) {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader
                        size="large"
                        data-testid="order-loading-view"
                    >
                        Mise à jour du panier...
                    </Loader>
                </Dimmer>
            )
        } else if (switchTo.fetchingSubstitutes === 'failed') {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader
                        size="large"
                        data-testid="order-loading-view"
                    >
                        Une erreur s'est produite! Recharger la page pour
                        réessayer...
                    </Loader>
                </Dimmer>
            )
        }
        else if (!switchToLocale.name) {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader
                        size="large"
                        data-testid="order-loading-view"
                    >
                        Chargement...
                    </Loader>
                </Dimmer>
            )
        }

        return (
            <div className={Styles.Container}>

                <LocationChange
                    currentLocale={currentLocale}
                    switchToLocale={switchToLocale}
                    check={switchTo.locale.code === currentLocale.code && switchTo.globalOrder._id === currentGlobalOrder._id}
                />

                <div className={Styles.tableContainer}>
                    {(switchTo.removedProducts &&
                        switchTo.removedProducts.length > 0) ?
                        <RemovedProducts
                            removedProducts={switchTo.removedProducts}
                        />
                        :
                        <div className={Styles.availableText}><p>Bonne nouvelle, <span>{switchTo.locale.code !== currentLocale.code && switchTo.globalOrder._id !== currentGlobalOrder._id && 'tous'}</span>les produits de votre panier sont <span>{switchTo.locale.code === currentLocale.code && switchTo.globalOrder._id === currentGlobalOrder._id && 'toujours '}</span>disponibles!</p></div>
                    }

                    {switchTo.availableProducts &&
                        switchTo.availableProducts.length > 0 && (
                            <AvailableProducts
                                availableProducts={switchTo.availableProducts}
                                orderRecovery={orderRecovery}
                            />
                        )}

                    {switchTo.substituteProducts &&
                        switchTo.substituteProducts.length > 0 && (
                            <ReplacedProducts
                                substituteProducts={switchTo.substituteProducts}
                                handleSubstituteQuantityUpdate={
                                    this.handleSubstituteQuantityUpdate
                                }
                            />
                        )}
                </div>

                <PersistentCartCorrectionBar
                    handleRevertSwitch={this.handleRevertSwitch}
                    handleConfirmSwitch={this.handleConfirmSwitch}
                    check={switchTo.locale.code === currentLocale.code && switchTo.globalOrder._id === currentGlobalOrder._id}
                />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCartItem: params => dispatch(updateCartItem(params)),
        fetchInit: () => dispatch(fetchInit()),
        selectGlobalOrder: params => dispatch(selectGlobalOrder(params)),
        selectLocale: params => dispatch(selectLocale(params)),
        fetchSubstitutes: params => dispatch(fetchSubstitutes(params)),
        updateSubstituteQuantity: params =>
            dispatch(updateSubstituteQuantity(params)),
        cancelSwitchTo: () => dispatch(cancelSwitchTo()),
        confirmSwitchTo: params => dispatch(confirmSwitchTo(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartCorrectionPage)

const ReplacedProducts = ({
    substituteProducts,
    handleSubstituteQuantityUpdate,
}) => {
    const getPath = (producerproduct_id, image_name) => {
        const path = `${producerproduct_id}/${image_name}`
        return addImgPrefix(path, 'detail')
    }
    const bumpUp = ({ productId, quantity }) => {
        const newQuantity = quantity + 1
        handleSubstituteQuantityUpdate({ productId, quantity: newQuantity })
    }

    const bumpDown = ({ productId, quantity }) => {
        const newQuantity = quantity - 1
        handleSubstituteQuantityUpdate({ productId, quantity: newQuantity })
    }
    // Provide a list of alternative products => substituteProducts
    return (
        <>
            <Warning theme="success">
                Ces produits sont remplacés par des équivalents :
            </Warning>
            <Table
                compact="very"
                basic
                unstackable
            >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            textAlign="center"
                            className={Styles.TableHeaderCellQuantity}
                        >
                            Quantit&eacute;
                        </Table.HeaderCell>
                        <Table.HeaderCell>Produit</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Valeur
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {substituteProducts &&
                        substituteProducts.length > 0 &&
                        substituteProducts.map((item, id) => (
                            <Table.Row
                                key={id}
                                className={Styles.TableRow}
                            >
                                <Table.Cell
                                    textAlign="center"
                                    className={Styles.TableQuantity}
                                >
                                    <Button.Group size="tiny">
                                        <Button
                                            icon="minus"
                                            primary
                                            onClick={() =>
                                                bumpDown({
                                                    productId:
                                                        item.producerproduct_id,
                                                    quantity: item.quantity,
                                                })
                                            }
                                            title="less"
                                        />

                                        <Button.Or text={item.quantity} />

                                        {item.quantity >= item.available ||
                                            item.quantity >= 8 ? (
                                            <Button
                                                icon="plus"
                                                primary
                                                disabled
                                            />
                                        ) : (
                                            <Button
                                                icon="plus"
                                                primary
                                                onClick={() =>
                                                    bumpUp({
                                                        productId:
                                                            item.producerproduct_id,
                                                        quantity: item.quantity,
                                                    })
                                                }
                                                title="more"
                                            />
                                        )}
                                    </Button.Group>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className={Styles.TableProduct}>
                                        <div
                                            className={
                                                Styles.TableImgProductContainer
                                            }
                                        >
                                            <img
                                                className={
                                                    Styles.TableImgProduct
                                                }
                                                src={getPath(
                                                    item.producerproduct_id,
                                                    item.image
                                                )}
                                                alt={item.product_name}
                                            />
                                        </div>
                                        <div>
                                            <div className={Styles.ProductName}>
                                                {item.product_name}
                                            </div>
                                            <div
                                                className={Styles.ProducerName}
                                            >
                                                {item.producer_name}
                                            </div>
                                            <div>
                                                {item.consumer_price / 100} € /{' '}
                                                {item.unit_display}
                                            </div>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell
                                    textAlign="center"
                                    className={Styles.ConsumerPrice}
                                >
                                    {item.consumer_price / 100} €
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </>
    )
}

const RemovedProducts = ({ removedProducts }) => {
    // transmit the list of products that cannot be replaced and therefore removed from the user's cart
    const listRemovedProducts =
        removedProducts && removedProducts.length > 0 &&
        removedProducts.map((product, id) => (
            <div
                key={id}
                className={Styles.RemovedProducts}
            >
                <div className={Styles.ProductName}>
                    {product.quantity} x {product.product_name}
                </div>
                <div className={Styles.ProducerName}>
                    {product.producer_name}
                </div>
                {id < removedProducts.length - 1 && (
                    <div className={Styles.Divide} />
                )}
            </div>
        ))

    return (
        <div className={Styles.listContainer}>
            <Warning theme="canceled">
                Ces produits ne sont pas disponibles et seront retirés de votre panier :
            </Warning>
            <div>{listRemovedProducts}</div>
        </div>
    )
}

const AvailableProducts = ({ availableProducts, orderRecovery }) => {
    // transmit the list of products that cannot be replaced and therefore removed from the user's cart
    const listAvailableProducts =
        availableProducts.length > 0 &&
        availableProducts.map((product, id) => (
            <div
                key={id}
                className={Styles.AvailableProducts}
            >
                <div className={Styles.ProductName}>
                    {product.quantity} x {product.product_name}
                </div>
                <div className={Styles.ProducerName}>
                    {product.producer_name}
                </div>
                {id < availableProducts.length - 1 && (
                    <div className={Styles.Divide} />
                )}
            </div>
        ))

    return (
        <div className={Styles.listContainer}>
            <Warning theme="success">
                Ces produits sont disponibles et seront <span>{orderRecovery ? 'replacés' : 'conservés'}</span> dans votre panier :
            </Warning>
            <div>{listAvailableProducts}</div>
        </div>
    )
}

const Warning = ({ theme, children }) => {
    let icon
    switch (theme) {
        case 'success':
            icon = 'checkmark'
            break
        case 'canceled':
            icon = 'close'
            break
        default:
            icon = 'checkmark'
    }
    return (
        <div className={theme === 'canceled' ? Styles.WarningGray : Styles.Warning}>
            <Icon
                name={icon}
                className={theme === 'canceled' ? Styles.IconGray : Styles.Icon}
                size="big"
            />
            <span>{children}</span>
        </div>
    )
}

const LocationChange = ({ currentLocale, switchToLocale, check }) => {
    return (
        <div className={Styles.LocationChangeContainer}>
            {!check &&
                <>
                    <div className={Styles.CurrentLocation}>
                        <div className={Styles.LocaleName}>{currentLocale?.name}</div>
                        <div className={Styles.schedule}>
                            {currentLocale?.currentSchedule?.day}{' '}
                            {currentLocale?.currentSchedule?.time}
                        </div>
                    </div>

                    <div className={Styles.LocationIconContainer}>
                        <Icon
                            name="right arrow"
                            className={Styles.LocationIcon}
                            size="large"
                        />
                    </div>
                </>
            }

            <div className={Styles.SelectLocation}>
                <div className={Styles.LocaleName}>{switchToLocale?.name}</div>
                <div className={Styles.schedule}>
                    {switchToLocale?.day} {switchToLocale?.time}
                </div>
            </div>
        </div>
    )
}
