import React from 'react'
import PropTypes from 'prop-types'
import { List, Icon, Button, Header, Image } from 'semantic-ui-react'
import { addImgPrefix } from '../../../../../utils'

import { CheckoutPaymentTokenItem } from './CheckoutPaymentTokenItem'

import Styles from './styles.module.css'

export const CheckoutPaymentTokenList = ({ tokens = [], handleSelection }) => {
    return (
        <div className={Styles.Container}>
            <div className={Styles.HeaderContainer}>
                <Header as="h2">
                    <Image src={addImgPrefix('coin.svg')} alt="Logo pièce de monnaie" />
                    Modes de paiement sauvegard&eacute;s
                </Header>
            </div>

            <div className={Styles.ListContainer}>
                <List
                    divided
                    relaxed
                    selection
                    size="big"
                >
                    {tokens.map((token, index) => (
                        <CheckoutPaymentTokenItem
                            handleSelection={handleSelection}
                            token={token}
                            index={index}
                        />
                    ))}
                </List>

                <div>
                    <Button
                        fluid
                        simple
                        onClick={() => handleSelection()}
                    >
                        <Icon name="plus" />
                        Payer avec une autre carte
                    </Button>
                </div>
            </div>
        </div>
    )
}

CheckoutPaymentTokenList.propTypes = {
    tokens: PropTypes.array,
    handleSelection: PropTypes.func.isRequired,
}
