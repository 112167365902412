export const mapStateToProps = ({
    currentUser,
    locales,
    ordersHistory,
    currentOrder,
    activeGlobalOrdersList,
}) => {
    const orderLocale =
        currentOrder && currentOrder.order && locales[currentOrder.order.locale]

    const orderGlobalOrder = activeGlobalOrdersList.find(
        g =>
            currentOrder &&
            currentOrder.order &&
            g._id === currentOrder.order.globalorder_id
    )

    return {
        blockInStorePayment:
            orderGlobalOrder && orderLocale
                ? orderLocale.partner ||
                  orderLocale.private ||
                  orderGlobalOrder.block_instore_payment
                : false,
        blockOrderChanges:
            orderGlobalOrder && orderGlobalOrder.block_order_changes,
        isHomeDelivery: orderLocale && orderLocale.home_delivery,
        isOrderLocalePrivate: orderLocale && orderLocale.private,
        loadingPaymentTokens: currentUser.loading,
        paymentTokens: currentUser.payment_tokens || [],
        serverError: !!ordersHistory.error,
    }
}
