import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'

import {
    updateCartItem,
    setOrderContext,
    openZoom,
    closeZoom,
    setSideMenuVisibility,
} from '../../../../actions'

import ProductZoom from '../ProductZoom'
import { PersistentContextBar } from '../PersistentContextBar'
import { PersistentCheckoutBar } from '../PersistentCheckoutBar'
import { mapStateToProps } from './helpers/mapStateToProps'
import { ProductsHeader } from './ProductsHeader'
import { CategoryView } from './CategoryView'

import Styles from './styles.module.css'

// temp
const addCart = () => () => { }

export class ProductList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            familyScrollIndex: false,
            open: false,
        }
        this.onPassNewSection = this.onPassNewSection.bind(this)
        this.onChangeValue = this.onChangeValue.bind(this)
        this.onOpenZoom = this.onOpenZoom.bind(this)
        this.onCloseZoom = this.onCloseZoom.bind(this)
    }

    componentDidMount() {
        const node = document.getElementById(`key-${this.props.startScroll}`)

        node &&
            node.scrollIntoView({
                block: 'start',
                behavior: 'instant',
            })
        window.scrollBy(0, -100)
    }

    onChangeValue({ productId, quantity, isConsumerCart = false }) {
        this.props.updateCartItem({ productId, quantity })

        if (this.props.zoomProduct._id === productId && !isConsumerCart) {
            const product = {
                ...this.props.zoomProduct,
                quantity,
            }
            this.props.openZoom({ product })
        }
    }

    onPassNewSection({ category, family }, e, { calculations }) {
        if (this.props.blockVisibility) {
            return
        }

        if (category && family && calculations && calculations.passing) {
            this.props.setOrderContext({ family, category })
        } else {
            /* make sure we put back the generic title when we re-scroll to the top */
            this.props.setOrderContext({ family: null, category: null })
        }
    }

    onOpenZoom({ product }) {
        this.props.openZoom({ product })
    }

    onCloseZoom() {
        this.props.closeZoom()
    }

    render() {
        const {
            cart,
            categories,
            currentDate,
            currentGlobalOrder,
            currentLocale,
            currentUser,
            families,
            hasCartItems,
            isGlobalOrderFull: isFull,
            productsHeader,
            productTree,
            scrollCategory,
            scrollFamily,
            setSideMenuVisibility,
            sideMenuVisible,
            zoomProduct,
        } = this.props

        return (
            <div
                id="key-top"
                data-testid="product-list"
            >
                {/* product detail modal */}
                <ProductZoom
                    open={!!zoomProduct ? true : false}
                    closeCallback={this.onCloseZoom}
                    cart={cart}
                    product={zoomProduct}
                    onChangeValue={this.onChangeValue}
                    isFull={isFull}
                />

                {/* Subheader beneath global header, shows current category / family */}
                <PersistentContextBar
                    category={categories[scrollCategory]}
                    currentDate={currentDate}
                    currentGlobalOrder={currentGlobalOrder}
                    family={families[scrollFamily]}
                    currentLocale={currentLocale}
                    setSideMenuVisibility={setSideMenuVisibility}
                    sideMenuVisible={sideMenuVisible}
                />

                {/* Dynamic seasonal header */}
                <ProductsHeader
                    productsHeader={productsHeader}
                    currentUser={currentUser}
                />

                <Grid className={Styles.contentRight}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {productTree.map(category => (
                                <CategoryView
                                    category={category}
                                    onPassNewSection={this.onPassNewSection}
                                    onChangeValue={this.onChangeValue}
                                    onOpenZoom={this.onOpenZoom}
                                    isFull={isFull}
                                    addCart={this.props.addCart}
                                />
                            ))}
                        </Grid.Column>
                    </Grid.Row>

                    {/* link to cart detail page */}
                    <PersistentCheckoutBar hasCartItems={hasCartItems} />
                </Grid>

                <div
                    style={{ height: '30px' }}
                    id="#tracker"
                ></div>
            </div>
        )
    }
}

ProductList.propTypes = {
    cart: PropTypes.object,
    currentLocale: PropTypes.object,
    currentUser: PropTypes.object,
    hasCartItems: PropTypes.bool,
    isGlobalOrderFull: PropTypes.bool,
    productsHeader: PropTypes.object,
    productTree: PropTypes.array,
    sideMenuVisible: PropTypes.bool,
    startScroll: PropTypes.string,
    scrollFamily: PropTypes.string,
    scrollCategory: PropTypes.string,
    zoomProduct: PropTypes.string,
    setSideMenuVisibility: PropTypes.func.isRequired,
    updateCartItem: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
    return {
        updateCartItem: params => dispatch(updateCartItem(params)),
        openZoom: params => dispatch(openZoom(params)),
        setOrderContext: params => dispatch(setOrderContext(params)),
        closeZoom: () => dispatch(closeZoom()),
        addCart: params => dispatch(addCart(params)),
        setSideMenuVisibility: ({ visible }) =>
            dispatch(setSideMenuVisibility({ visible })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
