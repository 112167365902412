import React from 'react'
import { Form, Icon, Header } from 'semantic-ui-react'
import Styles from '../styles.module.css'

export const CreateAccountValidationNotification = () => (
    <div
        className={Styles.Container}
        data-testid="create-account-validation-notification"
    >
        <Form>
            <Header
                as="h3"
                textAlign="center"
                icon
            >
                <Icon name="user outline" />
                Validation email requise!
            </Header>
            <Header.Subheader>
                Vérifiez votre boîte de messagerie, un email de notre part vous
                permettra de valider votre compte
            </Header.Subheader>
        </Form>
        {/* <p style={{textAlign: 'center', margin:'1.2em'}}>
    <Link to='/'>
      <Button icon primary size='large'>
        <Icon name='shopping bag' /> Accéder à la vente
      </Button>
    </Link>
    </p> */}
    </div>
)
