import { useCallback, useState, useLayoutEffect } from 'react'
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete'
import Styles from './styles.module.css'
import { Image } from 'semantic-ui-react'
import closeMap from '../../../../../../assets/closeMap.png'
import searchGlass from '../../../../../../assets/search.svg'

const PlacesAutocomplete = ({
    mapRef,
    setSelectedLatLng,
    isPositionAbsolute,
    locales,
    cardListRef
}) => {

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['fr'] },
            types: ['address'],
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    })

    function calculateDistance(latlng1, latlng2) {
        const { lat: lat1, lng: lng1 } = latlng1
        const { lat: lat2, lng: lng2 } = latlng2
        const toRad = angle => (Math.PI / 180) * angle
        const R = 6371 // Earth's radius in km

        const dLat = toRad(lat2 - lat1)
        const dLng = toRad(lng2 - lng1)

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) *
            Math.cos(toRad(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c

        return distance
    }

    const handleSelect = useCallback(
        async address => {
            setValue(address, false)
            clearSuggestions()

            if (window.innerWidth > 1000) {
                cardListRef.current.children[1].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }

            const results = await getGeocode({ address })
            const { lat, lng } = await getLatLng(results[0])

            let nearestLocale = null
            let minDistance = Infinity
            for (const locale of locales) {
                const distance = calculateDistance(
                    { lat, lng },
                    locale.geolocation
                )
                if (distance < minDistance) {
                    minDistance = distance
                    nearestLocale = locale
                }
            }

            if (nearestLocale && mapRef && mapRef.current) {
                clearSuggestions()
                mapRef.current.panTo({ lat, lng })
                mapRef.current.setZoom(13)
            }

            setSelectedLatLng({ lat: lat, lng: lng })
            document.getElementById("combo-box-input").blur()
        },
        [mapRef]
    )

    return (
        <div className={Styles.autocompleteContainer}>
            <Combobox
                onSelect={handleSelect}
                aria-label="renseignez une adresse"
                className={
                    isPositionAbsolute
                        ? Styles.comboboxContainerAbsolute
                        : Styles.comboboxContainer
                }
            >
                <ComboboxInput
                    value={value}
                    id='combo-box-input'
                    onChange={e => setValue(e.target.value)}
                    disabled={!ready}
                    placeholder={
                        window.innerWidth > 1000
                            ? 'Saisissez une adresse, un code postal, un arrondissement'
                            : 'Saisissez une adresse...'
                    }
                    className={Styles.comboboxInput}
                />
                <ComboboxPopover className={Styles.comboboxPopover}>
                    <ComboboxList>
                        {status === 'OK' &&
                            data.map(({ place_id, description }, index) => {
                                return (
                                    <ComboboxOption
                                        key={place_id}
                                        value={description}
                                    />
                                )
                            })}
                    </ComboboxList>
                </ComboboxPopover>
                {value.length > 0 ?
                    <Image
                        src={closeMap}
                        alt="réinitialiser"
                        className={Styles.image}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (window.innerWidth > 1000) {
                                cardListRef.current.children[1].scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                })
                            }
                            mapRef.current.panTo({
                                lat: 48.90,
                                lng: 2.41,
                            })
                            setSelectedLatLng(null)
                            setValue('')
                            mapRef.current.setZoom(11)
                        }}
                    />
                    :
                    <Image
                        src={searchGlass}
                        alt="Rechercher une adresse"
                        className={Styles.image}
                    />
                }
            </Combobox>
        </div>
    )
}

export default PlacesAutocomplete
