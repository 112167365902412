import { validateConsumerProfileUpdate } from './validateConsumerProfileUpdate'
import { diff } from './diff'

export const handleSubmit = function () {
    const currentUser = this.props.currentUser
    const changed = diff(currentUser, this.state)
    const checkoutInvalidDeliveryAddressRedirect =
        this.state.checkoutInvalidDeliveryAddressRedirect

    if (!validateConsumerProfileUpdate(currentUser, this.state)) {
        return
    }

    this.setState({
        loading: true,
    })

    // validate the form
    return this.props
        .updateParams(changed)
        .then(ok => {
            if (ok) {
                this.setState({
                    validated: false,
                    // showFormSuccess: true,
                    has_valid_delivery_address:
                        this.props.currentUser.has_valid_delivery_address,
                    redirectCheckout: checkoutInvalidDeliveryAddressRedirect,
                })
            } else {
                this.setState({
                    loading: false,
                    showFormError: true,
                })
            }
        })
        .finally(() => {
            this.setState({
                loading: false,
            })
        })
}
