import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Header, Dimmer, Loader, Image } from 'semantic-ui-react'

import { loadCheckoutData } from '../../../../actions'
import danger from '../../../../assets/signe-de-danger.png'

import ErrorBoundary from '../../../layout/ErrorBoundary'

import { trackEvent } from '../../../../utils/trackEvent'
import { mapItems } from './helpers/mapItems'
import { mapStateToProps } from './helpers/mapStateToProps'
import { StepGroup } from './StepGroup'
import CheckoutCartInfo from './CheckoutCartInfo'
import { CheckoutDeliveryInfo } from './CheckoutDeliveryInfo'
import { PersistentCheckoutBar } from './PersistentCheckoutBar'

import Styles from './styles.module.css'

export class CheckoutOrderConfirmationBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadedCheckoutData: false,
            salesExpiry: null
        }
    }

    UNSAFE_componentWillMount() {
        this.props
            .loadCheckoutData({
                globalorder_id: this.props.cart_globalorder_id,
                orderreservation_id: this.props.orderreservation_id,
            })
            .then(() => {
                this.setState({
                    loadedCheckoutData: true,
                })
            })

        const now = new Date()
        const expiry = new Date(this.props.currentGlobalOrder.order_end)
        this.setState({
            salesExpiry: Math.max(Math.round((expiry - now) / 1000 / 60), 0)
        })
    }

    render() {
        const {
            cartItems,
            amountToPay,
            addedCharges,
            currentLocale,
            creditUsed,
            currentUser,
            currentGlobalOrder,
            ready,
            loading,
            redirectToAccount,
            orderreservation_id,
            amountTotal,
            welcomeCredit,
        } = this.props

        if (!cartItems || cartItems.length === 0) {
            return <Redirect to="/" />
        }

        if (!ready || loading || !this.state.loadedCheckoutData) {
            return (
                <Dimmer
                    active
                    inverted
                    style={{ height: '300px' }}
                >
                    <Loader
                        inverted
                        content="Chargement"
                    />
                </Dimmer>
            )
        }

        if (redirectToAccount) {
            // gtm invalid address
            window.dataLayer.push({
                event: 'home-delivery-checkout-invalid-address',
            })

            return (
                <Redirect to="/account/details?invalidDeliveryAddress=true" />
            )
        }

        window.dataLayer &&
            window.dataLayer.push({
                event: 'begin_checkout',
                eventProps: {
                    currency: 'EUR',
                    value: cartItems.map(item => mapItems('gtm', item)).reduce((a, c) => a + (c.item_price * c.item_quantity), 0),
                    items: cartItems.map(item => mapItems('gtm', item)),
                },
            })

        trackEvent({
            _id: currentUser._id,
            event: 'Started Checkout',
            data: {
                $event_id: orderreservation_id + '_' + new Date().getTime(),
                $value: amountTotal / 100,
                Items: cartItems.map(item => mapItems('klaviyo', item)),
            },
        })

        return (
            <div data-testid="checkout-order-confirmation-container">
                <div className={Styles.Table}>
                    {this.state.salesExpiry === 0 ?
                        <div className={Styles.dangerContainer}>
                            <Image src={danger} alt="attention" style={{ width: '40px', height: '40px' }} />
                            <p style={{ fontSize: '0.9em', textAlign: 'start', marginLeft: '10px' }}>Cette vente est <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>clôturée</span><br />Nous vous invitons à sélectionner une autre vente pour passer votre commande</p>
                        </div>
                        : this.state.salesExpiry <= 60 &&
                        <div className={Styles.dangerContainer}>
                            <Image src={danger} alt="attention" style={{ width: '40px', height: '40px' }} />
                            <p style={{ fontSize: '0.9em', textAlign: 'start', marginLeft: '10px' }}>La vente se clôture dans <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{this.state.salesExpiry} min</span><br />Passé ce délai, la validation de votre commande ne sera pas prise en compte</p>
                        </div>
                    }
                    <StepGroup />

                    <div className={Styles.DeliveryInfoContainer}>
                        <Header
                            as="h2"
                            style={{ marginBottom: '2em' }}
                            content="Pour récupérer vos produits"
                            className={Styles.Header}
                        />
                        <CheckoutDeliveryInfo
                            currentLocale={currentLocale}
                            currentUser={currentUser}
                            currentGlobalOrder={currentGlobalOrder}
                        />
                    </div>

                    <div className={Styles.CartInfoContainer}>
                        <CheckoutCartInfo
                            cartItems={cartItems}
                            amountToPay={amountToPay}
                            addedCharges={addedCharges}
                            creditUsed={creditUsed}
                            welcomeCredit={welcomeCredit}
                        />
                    </div>
                </div>

                <PersistentCheckoutBar currentLocale={currentLocale} />
            </div>
        )
    }
}

const CheckoutOrderConfirmation = props => (
    <ErrorBoundary page="checkout-order-confirmation">
        <CheckoutOrderConfirmationBase {...props} />
    </ErrorBoundary>
)

const mapDispatchToProps = dispatch => {
    return {
        loadCheckoutData: params => dispatch(loadCheckoutData(params)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutOrderConfirmation)
