const convertToParseableRepresentation = (dateAsString) => dateAsString.replace(/-/g, '/')

export const mapStateToProps = ({
    currentUser,
    currentLocale,
    currentGlobalOrder,
    order,
}) => {
    const now = new Date()
    return {
        blockInStorePayment:
            currentLocale.partner ||
            currentLocale.private ||
            currentGlobalOrder.block_instore_payment,
        blockOrderChanges: currentGlobalOrder.block_order_changes,
        globalOrderAlreadyClosed: {
            distributionDate: new Date(currentGlobalOrder.distribution_date),
            ordersClosingDate: new Date(convertToParseableRepresentation(currentGlobalOrder.order_end)),
            status: order && typeof order.globalOrderAlreadyClosed === 'boolean' ? order.globalOrderAlreadyClosed : new Date(convertToParseableRepresentation(currentGlobalOrder.order_end)) < now ? true : false,
        },
        isHomeDelivery: currentLocale.home_delivery,
        isOrderLocalePrivate: currentLocale && currentLocale.private,
        loadingPaymentTokens: currentUser.loading,
        orderreservation_id: order.id,
        paymentTokens: currentUser.payment_tokens || [],
        serverError: order && !!order.error,
    }
}
