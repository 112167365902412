import { Cart, GlobalOrder, Consumer } from '../../../../../models'

export const mapStateToProps = ({
    cart: { list, items: quantities, globalorder_id: cart_globalorder_id },
    order: currentOrder,
    products,
    available: { products: available },
    currentUser,
    currentGlobalOrder,
    currentLocale,
    initRequest,
}) => {
    const isLocaleHomeDelivery = currentLocale && currentLocale.home_delivery

    const cart = new Cart({
        list,
        quantities,
        products,
        available,
        isLocalePrivate: currentLocale && !!currentLocale.private,
        isLocaleHomeDelivery,
        currentLocale,
    })
    const consumer = new Consumer(currentUser)
    const creditUsed = cart.getCreditUsed(currentUser.current_credit)
    const welcomeCredit = currentUser.welcome_credit || 0;
    const ready = !!initRequest.ready
    const loading = !!currentUser.loading
    const orderreservation_id = currentOrder && currentOrder.id
    const amountTotal = cart.getTotalTTC()

    const redirectToAccount =
        !!ready &&
        !loading &&
        isLocaleHomeDelivery &&
        !consumer.hasValidDeliveryAddress()

    return {
        cart_globalorder_id,
        orderreservation_id,
        cartItems: cart.items,
        amountTotal,
        addedCharges: cart.added_charges,
        amountToPay: amountTotal - creditUsed - welcomeCredit,
        currentGlobalOrder: new GlobalOrder(currentGlobalOrder),
        currentUser,
        loading,
        redirectToAccount,
        creditUsed,
        welcomeCredit,
        currentLocale,
        ready,
    }
}
