import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Image} from 'semantic-ui-react'
import {Redirect} from 'react-router-dom'
import Secure from '../../../../../assets/3D-Secure.jpg'
import {componentDidMount} from './helpers'
import {CheckoutPaymentCBIframe} from './CheckoutPaymentCBIframe'

import Styles from './styles.module.css'
import {withRouter} from "react-router";
import {formatCurrency} from "../../../../../utils/formatCurrency";

const CancelButton = withRouter(({history, orderId}) => (
    <Button primary basic
            content="Abandonner"
            size="huge"
            icon="left arrow"
            labelPosition="left"
            onClick={() => history.push(`/account/orders/${orderId}`)}
    />
))

export class CheckoutPaymentCB extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.retryPayment = this.retryPayment.bind(this)
        this.componentDidMount = componentDidMount.bind(this)
    }

    /* componentDidMount (helpers) */

    retryPayment() {
        this.setState({ hasTimeout: false })
    }

    render() {
        const {paymentFormData} = this.props;
        const {vads_order_id, vads_amount} = paymentFormData.data;

        if (this.state.paymentSuccess) {
            return <Redirect to={`/checkout/commande-confirmee?order_id=${vads_order_id}`}/>
        }

        if (this.state.paymentFailure) {
            return <Redirect to={`/checkout/commande-echouee?order_id=${vads_order_id}`}/>
        }

        if (this.state.hasTimeout) {
            return (
                <div style={{width: '100%'}}>
                    <div
                        style={{
                            marginBottom: '30px',
                            fontSize: '1.2em',
                            textAlign: 'center',
                        }}
                    >
                        La tentative de paiement a expir&eacute;.
                    </div>
                    <div>
                        <Button
                            fluid
                            primary
                            text="Nouvelle tentative"
                            onClick={this.retryPayment}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className={Styles.CheckoutPaymentCB}>
                <div className={Styles.HeaderContainer}>
                    <div className={Styles.Header}>
                        <Image src={Secure} size="tiny"/>
                        Paiement de votre commande Kelbongoo ({formatCurrency(vads_amount)})
                    </div>
                </div>

                <div>
                    <CheckoutPaymentCBIframe {...paymentFormData} />
                </div>

                <div>
                    <CancelButton orderId={vads_order_id}/>
                </div>
            </div>
        )
    }
}

CheckoutPaymentCB.propTypes = {
    paymentFormData: PropTypes.object,
}
