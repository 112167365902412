import React, { Component, Fragment } from 'react'
import { Navigate } from "react-router-dom";
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Form, Icon, Header, Message } from 'semantic-ui-react'

import { login } from '../../../actions'
import { getUrlParameter } from '../../../utils'
import ErrorBoundary from '../../layout/ErrorBoundary'
import { handleSubmit } from './handleSubmit'
import store from '../../../store'

import Styles from './styles.module.css'

export class LoginBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
        }

        this.handleSubmit = handleSubmit.bind(this)
    }


    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, error: false })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { currentUser, cart } = this.props
        const errorText =
            currentUser.error && currentUser.error.message === 'foo'
                ? 'Cette adresse est déjà liée à un compte Kelbongoo. Un trou de mémoire ? Pensez à réinitialiser le mot de passe'
                : "L'adresse mail et le mot de passe ne correspondent pas !"

        if (currentUser.loggedin) {
            // return <Redirect to={this.props.goto || '/commande'} />
            if (cart.list.length > 0) {
                setTimeout(() => {
                    this.props.history.push('/commande')
                    window.location.reload()
                }, 250)
            }
            else {
                setTimeout(() => {
                    this.props.history.push('/choisir-un-magasin')
                    window.location.reload()
                }, 250)
            }
        }

        return (
            <Fragment>
                <div className={Styles.Container}>
                    <Form onSubmit={this.handleSubmit}>
                        <Header
                            as="h3"
                            textAlign="center"
                            icon
                        >
                            <Icon name="user" />
                            Merci de vous identifier
                        </Header>

                        <Form.Input
                            label="Email"
                            id="email"
                            placeholder="user@email.com"
                            name="email"
                            required
                            error={this.state.error}
                            onChange={this.handleChange}
                        />

                        <Form.Input
                            label="Mot de Passe"
                            id="password"
                            placeholder=""
                            name="password"
                            type="password"
                            required
                            error={this.state.error}
                            onChange={this.handleChange}
                        />

                        {this.state.error ? (
                            <Message negative>{errorText}</Message>
                        ) : (
                            ''
                        )}

                        <Form.Button
                            icon
                            color="black"
                            fluid
                            labelPosition="right"
                            loading={currentUser.loading ? true : false}
                            size="huge"
                        >
                            Connexion
                            <Icon name="right arrow" />
                        </Form.Button>

                        <Header
                            as="h3"
                            textAlign="center"
                        >
                            Pas encore de compte?
                            <Header.Subheader>
                                Créez votre compte pour profiter des services
                                Kelbongoo
                            </Header.Subheader>
                        </Header>

                        <Link to="/creer-un-compte">
                            <Form.Button
                                icon
                                primary
                                fluid
                                labelPosition="right"
                                size="huge"
                            >
                                Créer un compte
                                <Icon name="right arrow" />
                            </Form.Button>
                        </Link>

                        <Header
                            as="h3"
                            textAlign="center"
                        >
                            Un trou de mémoire?
                            <Header.Subheader>
                                Vous pouvez créer un nouveau mot de passe
                            </Header.Subheader>
                        </Header>

                        <Link to="/mot-de-passe-oublie">
                            <Form.Button
                                icon
                                fluid
                                labelPosition="right"
                                size="large"
                            >
                                Mot de passe oublié
                                <Icon name="right arrow" />
                            </Form.Button>
                        </Link>
                    </Form>
                </div>
            </Fragment>
        )
    }
}

const Login = props => (
    <ErrorBoundary page="login">
        <LoginBase {...props} />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, cart }, { location }) => ({
    currentUser,
    cart,
    goto: getUrlParameter('goto', location) || '/choisir-un-magasin',
})

const mapDispatchToProps = dispatch => {
    return {
        login: payload => dispatch(login(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
