import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Popup, Transition } from 'semantic-ui-react'

import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import CartPopupTrigger from './CartPopupTrigger'
import CartPopupList from './CartPopupList'

import Styles from './styles.module.css'

class CartPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            cartUpdateOpen: false,
        }
        this.handleCartChange = this.handleCartChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    handleOpen = () => {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true })
        }
    }

    handleClose = () => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false })
        }
    }

    handleCartChange({ productId, quantity }) {
        this.props.updateCartItem({
            productId,
            quantity,
            showCartNotification: false,
        })
    }

    render() {
        const windowHeight = window.innerHeight
        const windowWidth = window.innerWidth

        const itemsToShow = Math.floor((windowHeight - 200) / 45)
        const { cart, cartUpdateOpen } = this.props
        const cartItems = cart.getLastNItems(itemsToShow)
        const cartAddedCharges = cart.added_charges
        const notShown = cart.getRemainingCount(itemsToShow)
        const currentCartQuantity = cart.getTotalQuantity()
        const currentCartValue = cart.getTotalTTC()

        return (
            <>
                <Popup
                    trigger={
                        <CartPopupTrigger
                            currentCartQuantity={currentCartQuantity}
                            className={Styles.cartItem}
                        />
                    }
                    content={
                        <CartPopupList
                            items={cartItems}
                            addedCharges={cartAddedCharges}
                            notShown={notShown}
                            currentCartValue={currentCartValue}
                            handleCartChange={this.handleCartChange}
                            handleClose={this.handleClose}
                            id="CartPopupList"
                        />
                    }
                    position="bottom right"
                    on="click"
                    offset={[-5, 0]}
                    style={windowWidth < 500 ? { width: '275px', position: 'absolute', top: '0', right: '0' } : { width: '320px', marginTop: '15px' }}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    hideOnScroll
                />

                <Transition.Group
                    animation="swing down"
                    duration={500}
                >
                    {cartUpdateOpen ? (
                        <Link
                            to="/cart"
                            className={Styles.CartUpdate}
                        >
                            <Button
                                basic
                                size="large"
                                content={formatMoney.run(
                                    currentCartValue,
                                    false
                                )}
                                icon="eur"
                                labelPosition="left"
                                primary
                            />
                        </Link>
                    ) : (
                        ''
                    )}
                </Transition.Group>
            </>
        )
    }
}

export default CartPopup

CartPopup.propTypes = {
    updateCartItem: PropTypes.func.isRequired,
    cart: PropTypes.object.isRequired,
    cartUpdateOpen: PropTypes.bool,
}
