export const isUrlDeliveryAddressInvalid = props => {
    let query = props.location.search
        .slice(1)
        .split('&')
        .reduce((a, i) => {
            let item = i.split('=')
            a[item[0]] = item[1]
            return a
        }, {})
    return !!query.invalidDeliveryAddress
}
