import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

const NotFound = () => (
    <div style={{ width: '100%', textAlign: 'center', paddingTop: '5em' }}>
        <Header
            as="h2"
            icon
        >
            <Icon name="question circle outline" />
            Page non trouv&eacute;e
            <Header.Subheader>
                Nous n'avons pas trouv&eacute; la page que vous cherchiez.
            </Header.Subheader>
        </Header>
    </div>
)

export default NotFound
