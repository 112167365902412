import * as Types from '../actions/types'

const locales = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.FETCH_LOCALES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case Types.FETCH_LOCALES_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case Types.FETCH_LOCALES_FAIL: // also dispatches to initRequest to manage the error
            return {
                loading: false
            }

        case Types.LOCALES_UPDATE:
            return action.dict

        case Types.FETCH_CONTENTFUL_LOCALES_SUCCESS:
            const current = state

            for (let l of action.items) {
                if (current[l.slug]) {
                    current[l.slug].image = l.image
                    current[l.slug].horaires = l.horaires
                    current[l.slug].subtitle = l.subtitle
                }
            }

            return current

        default:
            return state
    }
}

export default locales
