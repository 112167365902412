import { ConsumerCart, GlobalOrder } from '../../../../../models'
import { buildProductTree } from './buildProductTree'
import { getProductsHeader } from './getProductsHeader'

export const mapStateToProps = (
    {
        categories,
        families,
        producers,
        products,
        cart,
        currentUser,
        initRequest,
        consumercarts,
        currentLocale,
        switchTo,
        productsHeaderContent,
        currentGlobalOrder,
        available: { products: availableProducts },
        session: {
            sideMenuVisible = false,
            zoomProduct = false,
            family: scrollFamily,
            category: scrollCategory,
        },
    },
    { startScroll }
) => {
    const globalorder = new GlobalOrder(currentGlobalOrder)
    const closed_tags = globalorder.closed_tags

    consumercarts = Object.values(consumercarts).map(
        c => new ConsumerCart(c, { available: availableProducts, products })
    )
    //.filter(c => c.isAvailable())

    let productTree = buildProductTree({
        availableProducts,
        cart,
        categories,
        closed_tags,
        consumercarts,
        families,
        initRequest,
        producers,
        products,
    })

    const hasCartItems = cart.list.length > 0

    return {
        cart,
        categories,
        currentGlobalOrder: globalorder,
        currentLocale,
        currentUser,
        families,
        hasCartItems,
        isGlobalOrderFull: globalorder.isFull(),
        productsHeader: getProductsHeader({
            items: productsHeaderContent.content,
            locale: switchTo.locale ? switchTo.locale : currentLocale && currentLocale,
            globalOrder: switchTo.globalOrder ? switchTo.globalOrder : currentGlobalOrder && currentGlobalOrder,

        }),
        productTree,
        scrollCategory,
        scrollFamily,
        switchTo,
        sideMenuVisible,
        startScroll,
        zoomProduct,
    }
}
