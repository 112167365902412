export const handleChange = function (e, data) {
    let { name, value } = e.target

    let homedeliveryLocale = this.props.locales.find(l => !!l.home_delivery)

    if (!name) {
        ; ({ name, value } = data)
    }

    const update = {
        [name]: value,
        error: false,
    }

    if (name === 'default_locale') {
        if (homedeliveryLocale && value === homedeliveryLocale.code) {
            update.showAddressForm = true
        } else {
            update.showAddressForm = false
        }

        // gtm select locale at signup
        window.dataLayer &&
            window.dataLayer.push({
                event: 'select_item',
                eventProps: {
                    boutique: data.value,
                },
            })
    }

    this.setState(update)
}
