// @ts-check
import * as Types from '../../../actions/types'
import { fetchCurrentConsumerCredit } from '../../account/fetchCurrentConsumerCredit'
import { fetchLocales } from '../../distribution/fetchLocales'
import { fetchInit } from '../../distribution/fetchInit'
import { fetchUserInfo } from '../../account/fetchUserInfo'

const completeUserInfo = async ({ dispatch, currentUser, currentLocale, globalorder_id, orderreservation_id }) => {
    let promise = Promise.resolve()

    if (
        !currentUser._id ||
        !currentLocale ||
        (currentLocale.home_delivery && !currentUser.address_street)
    ) {
        promise = promise.then(() => dispatch(fetchUserInfo()))
    }

    await promise
    return dispatch(fetchCurrentConsumerCredit(globalorder_id, orderreservation_id))
}

export const loadCheckoutData = ({ globalorder_id, currentDate, orderreservation_id }) => {
    return (dispatch, getState) => {
        let {
            initRequest: { ready },
            currentUser,
            currentLocale,
        } = getState()

        /*
      by the end, we need to ensure we have currentGlobalOrder, currentUser (+ maybe address) and at least
      products for my cart
      if ready = true, it means we have ALL the products AND currentGlobalOrder
    */
        if (!ready) {
            return dispatch(fetchLocales()).then(activeGlobalOrders => {
                const currentGlobalOrder = activeGlobalOrders.find(
                    g => g._id === globalorder_id
                )

                dispatch({
                    type: Types.CURRENT_GLOBAL_ORDER_UPDATE,
                    currentGlobalOrder,
                })

                return dispatch(fetchInit(currentDate)).then(() =>
                    completeUserInfo({ dispatch, currentUser, currentLocale, globalorder_id, orderreservation_id })
                )
            })
        }

        return completeUserInfo({ dispatch, currentUser, currentLocale, globalorder_id, orderreservation_id })
    }
}
