import { useState, useLayoutEffect } from 'react'
import { addImgPrefix } from '../../../../../utils'
import { Image } from 'semantic-ui-react'
import Styles from './styles.module.css'
import DateSlider from '../DateSlider'
import LocationPin from '../../../../../assets/location_pin.svg'
import BoutiqueIcon from '../../../../../assets/boutique.svg'
import PointRelaisIcon from '../../../../../assets/point-relais.svg'
import PointPartenaireIcon from '../../../../../assets/point-partenaire.svg'
import ChevronUp from '../../../../../assets/chevron-up.svg'
import ChevronDown from '../../../../../assets/chevron-down.svg'
import newLocale from '../../../../../assets/new-locale.png'
import newPrivateLocale from '../../../../../assets/new-private-locale.png'
import BackgroundImage from '../../../../ui/BackgroundImg'

const CardPartenaire = ({
    locale,
    mapRef,
    currentUser,
    localeLength,
    openPanel,
    index,
    setOpenPanel,
    setMapCenter,
    setSelectedBoutique,
    handleNavigate,
    selectedBoutique,
}) => {
    const handleAddLocale = schedule => {
        handleNavigate(locale, schedule)
    }

    const zoomInLocale = locale => {
        if (window.innerWidth > 500) {
            // Zoom to boutique location
            const { lat, lng } = locale.geolocation
            if (mapRef && mapRef.current) {
                setMapCenter(locale.geolocation)
                mapRef.current.panTo({ lat, lng })
                mapRef.current.setZoom(14)
            }
        }
    }

    const localSchedule = locale.schedules.filter(function (val) {
        if (val === '' || val === undefined || val == null) {
            return false
        }
        return true
    })

    const distributions = locale.distributions
    const dayArray = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

    const posMapping = { 0: 'Boutique', 1: 'Point Partenaire', 2: 'Point Relais' }

    let { cardContainer } = Styles

    if (selectedBoutique === locale) {
        cardContainer = Styles.cardContainerSelected;
    } else if (locale.new && !locale.company_domain) {
        cardContainer = Styles.cardContainerNew;
    } else if (locale.company_domain) {
        cardContainer = Styles.cardContainerPrivateLocale;
    }

    return (
        <div className={cardContainer}>
            <div className={Styles.innerContainer}>
                <BackgroundImage
                    src={addImgPrefix(`${locale.code}.JPG`, 'boutique')} alt={`boutique ${locale.code}`}
                />

                <div className={Styles.infoContainer}>
                    <div className={Styles.titleContainer}>
                        <h2
                            className={Styles.title}
                            onClick={() => {
                                const { lat, lng } = locale.geolocation

                                // Assuming you have a mapRef reference to your map component
                                if (mapRef && mapRef.current) {
                                    setMapCenter(locale.geolocation)
                                    // Assuming your map component exposes a method to pan to the location
                                    mapRef.current.panTo({ lat, lng })
                                    mapRef.current.setZoom(16) // You can adjust the zoom level as needed
                                }
                            }}
                        >
                            {locale.name}
                        </h2>
                        {locale.new && !locale.company_domain ? <Image className={Styles.sideImage} alt="nouveau" src={newLocale} /> : locale.company_domain && <Image className={Styles.sideImage} alt="nouveau" src={newPrivateLocale} />}
                    </div>
                    <div className={Styles.flex}>
                        <Image className={Styles.cardIcons} src={LocationPin} alt="" />
                        <p className={Styles.address}>
                            {(locale.address.street && locale.address.street.split(',').length > 1 ? locale.address.street.split(',')[0] : locale.address.street) + ', ' + locale.address.zip + ' ' + locale.address.city}
                        </p>
                    </div>

                    <div className={Styles.dayOpenedContainer}>
                        <>
                            {locale.type === 0 ?
                                <Image className={Styles.cardIcons} src={BoutiqueIcon} alt="" />
                                :
                                locale.type === 1 ?
                                    <Image className={Styles.cardIcons} src={PointPartenaireIcon} alt="" />
                                    :
                                    locale.type === 2 &&
                                    <Image className={Styles.cardIcons} src={PointRelaisIcon} alt="" />
                            }
                            <p className={Styles.undertitle}>
                                {posMapping[locale.type]}
                            </p>
                            {dayArray.map((day, index) => {
                                return (
                                    <div
                                        className={
                                            distributions.find(d => d.day === index + 1)
                                                ? Styles.dayContainerGreen
                                                : Styles.dayContainerGrey
                                        }
                                    >
                                        {day}
                                    </div>
                                )
                            })}
                        </>
                    </div>
                    <div
                        className={Styles.expandContainer}
                        onClick={() => {
                            const newArr = new Array(localeLength).fill(false)

                            if (openPanel[index] === true) {
                                newArr[index] = false
                                setOpenPanel(newArr)
                            } else {
                                newArr[index] = true
                                setOpenPanel(newArr)
                            }
                        }}
                    >
                        <p className={Styles.pointRetrait}>
                            {openPanel[index]
                                ? 'Réduire'
                                : 'Choisir un jour de retrait'}
                        </p>
                        {openPanel[index] ? (
                            <Image style={{ width: '20px' }} src={ChevronUp} alt="cacher ventes" />
                        ) : (
                            <Image style={{ width: '20px' }} src={ChevronDown} alt="montrer ventes" />
                        )}
                    </div>
                </div>
            </div>

            {openPanel[index] && (
                <DateSlider
                    setSelectedBoutique={setSelectedBoutique}
                    currentUser={currentUser}
                    selectedBoutique={selectedBoutique}
                    locale={locale}
                    zoomInLocale={zoomInLocale}
                    localeSchedule={localSchedule}
                    handleNavigate={handleAddLocale}
                />
            )}
        </div>
    )
}

export default CardPartenaire
