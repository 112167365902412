import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Icon, Header, Popup, Select, Grid } from 'semantic-ui-react'

import {
    createConsumer,
    fetchLocales,
    fetchSignupReasons,
} from '../../../actions'
import ErrorBoundary from '../../layout/ErrorBoundary'

import { CreateAccountValidationNotification } from './CreateAccountValidationNotification'
import { CreateAccountFooter } from './CreateAccountFooter'
import { handleChange } from './handleChange'
import { handleSubmit } from './handleSubmit'

import Styles from './styles.module.css'

export class CreateAccountBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
            validationRequired: props.validationRequired || false,
            default_locale: props.currentLocale && props.currentLocale.code,
            showAddressForm: props.showAddressForm,
        }

        this.handleSubmit = handleSubmit.bind(this)
        this.handleChange = handleChange.bind(this)
    }

    UNSAFE_componentWillMount() {
        if (!this.props.locales || this.props.locales.length === 0) {
            this.props.fetchLocales()
        }

        if (!this.props.signupReasons) {
            this.props.fetchSignupReasons()
        }

        window.scrollTo(0, 0)
    }

    render() {
        const { currentUser, locales, signupReasons, currentLocale } =
            this.props
        const showAddressForm = this.state.showAddressForm

        const localeOptions = locales
            .filter(l => l.code !== 'PRO' && !l.private)
            .map(l => ({
                key: l.code,
                value: l.code,
                text: l.name,
            }))
        const signupReasonOptions =
            signupReasons && signupReasons.length > 0
                ? signupReasons.map((r, i) => ({
                      key: i,
                      text: r.text,
                      value: r.code,
                  }))
                : []

        if (currentUser.loggedin) {
            return <Redirect to="/commande" />
        }

        if (this.state.validationRequired) {
            return <CreateAccountValidationNotification />
        }
        return (
            <div
                className={`${Styles.Container} ${
                    showAddressForm ? Styles.includeAddress : ''
                }`}
            >
                <Form
                    onSubmit={this.handleSubmit}
                    data-testid="form"
                >
                    <Grid stackable>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Header
                                    as="h3"
                                    textAlign="center"
                                    icon
                                >
                                    <Icon name="user plus" />
                                    Créer un compte
                                    <Header.Subheader>
                                        Créez un compte pour profiter des
                                        services Kelbongoo.
                                    </Header.Subheader>
                                    <Header.Subheader
                                        className={Styles.Highlight}
                                    >
                                        Nous nous engageons à ne jamais donner
                                        ou vendre vos données à des tiers.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={showAddressForm ? 2 : 1}>
                            <Grid.Column>
                                <Form.Input
                                    label="Prénom"
                                    placeholder="Jean"
                                    id="firstname"
                                    name="firstname"
                                    required
                                    value={this.state.firstname || ''}
                                    onChange={this.handleChange}
                                />

                                <Form.Input
                                    label="Nom"
                                    placeholder="Dupont"
                                    id="lastname"
                                    name="lastname"
                                    required
                                    value={this.state.lastname || ''}
                                    onChange={this.handleChange}
                                />

                                <Form.Input
                                    label="Email"
                                    placeholder="jean.dupont@email.com"
                                    id="email"
                                    name="email"
                                    required
                                    value={this.state.email || ''}
                                    onChange={this.handleChange}
                                />

                                <Form.Field>
                                    <label>
                                        Lieu de retrait
                                        pr&eacute;f&eacute;r&eacute;
                                        <Popup
                                            trigger={
                                                <Icon name="question circle" />
                                            }
                                            content="La boutique où vous commandez habituellement.
                        Vous pouvez toujours choisir une autre boutique si vous voulez !"
                                        />
                                    </label>
                                    <Form.Select
                                        fluid
                                        required
                                        control={Select}
                                        options={localeOptions}
                                        placeholder="Choisir une boutique"
                                        name="default_locale"
                                        value={this.state.default_locale}
                                        onChange={this.handleChange}
                                        data-testid="default_locale"
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Comment avez-vous connu Kelbongoo?
                                    </label>
                                    <Form.Select
                                        fluid
                                        required
                                        control={Select}
                                        options={signupReasonOptions}
                                        name="signup_reason"
                                        placeholder="Choisir une raison"
                                        onChange={this.handleChange}
                                        data-testid="signup_reason"
                                    />
                                </Form.Field>
                            </Grid.Column>

                            {showAddressForm ? (
                                <Grid.Column>
                                    <Form.Input
                                        label="Téléphone"
                                        placeholder="Numéro de téléphone"
                                        id="telephone"
                                        name="telephone"
                                        required
                                        onChange={this.handleChange}
                                    />

                                    <Form.Input
                                        label="Confirmer téléphone"
                                        placeholder=""
                                        id="telephone_confirm"
                                        name="telephone_confirm"
                                        required
                                        onChange={this.handleChange}
                                    />

                                    <Form.Input
                                        style={{ marginBottom: '1em' }}
                                        label="Rue"
                                        placeholder="ex. 123 avenue Jean Jaures"
                                        id="address_street"
                                        name="address_street"
                                        required
                                        onChange={this.handleChange}
                                    />

                                    <Form.Group>
                                        <Form.Field
                                            width={10}
                                            style={{ marginBottom: '1em' }}
                                            required
                                        >
                                            <label htmlFor="address_city">
                                                Ville
                                            </label>
                                            <input
                                                id="address_city"
                                                name="address_city"
                                                required
                                                placeholder="Montreuil"
                                                onChange={this.handleChange}
                                                value={this.state.address_city}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            width={6}
                                            style={{ marginBottom: '1em' }}
                                            required
                                        >
                                            <label htmlFor="address_zip">
                                                Code postal
                                            </label>
                                            <input
                                                id="address_zip"
                                                name="address_zip"
                                                required
                                                placeholder="93100"
                                                onChange={this.handleChange}
                                                value={this.state.address_zip}
                                            />
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Input
                                        style={{ marginBottom: '1em' }}
                                        label="Code immeuble"
                                        placeholder="1234A"
                                        id="address_code"
                                        name="address_code"
                                        onChange={this.handleChange}
                                    />

                                    <Form.Field style={{ marginBottom: '1em' }}>
                                        <label htmlFor="address_instructions">
                                            Instructions &agrave; donner au
                                            livreur ou &agrave; la livreuse
                                        </label>
                                        <textarea
                                            id="address_instructions"
                                            name="address_instructions"
                                            rows={3}
                                            placeholder="Instructions supplémentaires (200 caractères maximum)"
                                            onChange={this.handleChange}
                                            value={
                                                this.state.address_instructions
                                            }
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            ) : (
                                ''
                            )}
                        </Grid.Row>

                        <CreateAccountFooter
                            currentLocale={currentLocale}
                            currentUser={currentUser}
                            error={this.state.error}
                            showAddressForm={showAddressForm}
                        />
                    </Grid>
                </Form>
            </div>
        )
    }
}

const CreateAccount = props => (
    <ErrorBoundary page="create-account">
        <CreateAccountBase {...props} />
    </ErrorBoundary>
)

const mapStateToProps = ({
    currentUser,
    currentLocale,
    locales,
    consumerSignupReasons,
}) => ({
    currentUser,
    signupReasons: consumerSignupReasons.reasons,
    locales: Object.values(locales),
    currentLocale:
        currentLocale && currentLocale.code ? currentLocale : { code: 'BOR' }, // ie allow signup even if total technical chaos...
    showAddressForm: !!currentLocale && !!currentLocale.home_delivery,
})

const mapDispatchToProps = dispatch => {
    return {
        createConsumer: params => dispatch(createConsumer(params)),
        fetchLocales: () => dispatch(fetchLocales()),
        fetchSignupReasons: () => dispatch(fetchSignupReasons()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
